import {
  vInt,
  vLiteral,
  vMinValue,
  vNumber,
  vObject,
  vOutput,
  vPicklist,
  vPipe,
  vVariant,
} from '../validation'
import { temperatureUOM } from './common'

const vMinOne = vPipe(vNumber(), vInt(), vMinValue(1))

export const equipmentAccessorialSchema = vVariant('key', [
  vObject({
    key: vLiteral('chains'),
    count: vMinOne,
  }),
  vObject({
    key: vLiteral('e-track'),
  }),
  vObject({
    key: vLiteral('food-grade'),
  }),
  vObject({
    key: vLiteral('liftgate'),
  }),
  vObject({
    key: vLiteral('load-bars'),
    count: vMinOne,
  }),
  vObject({
    key: vLiteral('pipe-stakes'),
  }),
  vObject({
    key: vLiteral('protect-from-freezing'),
  }),
  vObject({
    key: vLiteral('straps'),
    count: vMinOne,
  }),
  vObject({
    key: vLiteral('tanker-endorsement'),
  }),
  vObject({
    key: vLiteral('tarps'),
    size: vPicklist([4, 6, 8]),
  }),
  vObject({
    key: vLiteral('team-driver'),
  }),
  vObject({
    key: vLiteral('temp'),
    min: vNumber(),
    max: vNumber(),
    uom: vPicklist(temperatureUOM.map(t => t.key)),
  }),
])
export type EquipmentAccessorial = vOutput<typeof equipmentAccessorialSchema>

export const ltlEquipmentAccessorial = vObject({
  key: vLiteral('protect-from-freezing'),
})

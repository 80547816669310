import { useState } from 'react'

import { ValidatedForm } from '@fv/client-components'

import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminCollapse } from '../../../components/shared/AdminCollapse'
import { AdminFormGroup } from '../../../components/shared/AdminFormGroup'
import { AdminTextField } from '../../../components/shared/AdminTextField'
import { ConfirmPickupForm } from '../../dispatches/ConfirmPickupForm'
import { useAddDispatchError } from '../../dispatches/mutations'
import { type LoadPanelProps } from './types'

type Props = LoadPanelProps
export const InternalDispatchForm = ({ load, onChange }: Props) => {
  return (
    <div className="border">
      <AdminCollapse
        title="Enter pickup number"
        name="internal"
        titleClassName="bg-base-200"
      >
        <ConfirmPickupForm load={load} onSubmit={onChange} />
      </AdminCollapse>
      <AdminCollapse
        title="Enter error message"
        name="internal"
        titleClassName="bg-base-200"
      >
        <DispatchErrorForm load={load} onChange={onChange} />
      </AdminCollapse>
    </div>
  )
}

const DispatchErrorForm = ({ load, onChange }: LoadPanelProps) => {
  const [errorMsg, setErrorMsg] = useState('')
  const addDispatchError = useAddDispatchError()
  const handleSubmit = async () => {
    try {
      await addDispatchError.mutateAsync({
        loadId: load._id,
        message: errorMsg,
      })
      onChange?.()
    } catch {
      //empty
    }
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <AdminFormGroup className="my-3" label="Error Message">
        <AdminTextField
          disabled={addDispatchError.isLoading}
          name="pickupConfirmationNumber"
          onChange={e => setErrorMsg(e.target.value)}
          value={errorMsg}
        />
      </AdminFormGroup>

      <AdminButton
        className="btn-primary"
        type="submit"
        loading={addDispatchError.isLoading}
      >
        Save error message
      </AdminButton>
    </ValidatedForm>
  )
}

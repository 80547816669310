import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

export const AdminAlert = ({
  children,
  className,
  title,
}: PropsWithChildren<{ className?: string; title?: string }>) => {
  return (
    <div className={clsx('alert alert-error', className)}>
      {!!title && <div>{title}</div>}
      {children}
    </div>
  )
}

import { useQuery } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type AdminLoad } from '../types/admin-load'
import { adminFetch } from '../utils'

async function fetchLoad(id: string) {
  return adminFetch<AdminLoad>(`/loads/${id}`)
}

export function useFetchLoad(id?: string) {
  return useQuery<AdminLoad>(['load', id], () => fetchLoad(id ?? ''), {
    enabled: Boolean(id),
    onError: (error: any) => {
      toast.error(error.message)
    },
  })
}

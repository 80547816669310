import {
  vArray,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import { Currency, Mode, modes, WeightUOM } from './common'
import { EquipmentAccessorial, equipmentAccessorialSchema } from './equipment'
import { EquipmentType, vEquipmentType } from './equipment-type'

export const loadEquipmentSchema = vObject({
  // loadId: vObjectId(),
  // quoteId: vOptional(vObjectId()),
  mode: vPicklist([...modes]),
  type: vEquipmentType(),
  alternateTypes: vOptional(vArray(vEquipmentType())),
  accessorials: vOptional(vArray(equipmentAccessorialSchema)),
  description: vOptional(vString()),
  // declaredValue: vOptional(vNumber()),
  // declaredValueCurrency: vCurrency(),
  // weight: vNumber(),
  // weightUOM: vWeightUOM,
  // isHazardous: vBoolean(),
})
export type LoadEquipmentDto = vOutput<typeof loadEquipmentSchema>
export interface LoadEquipment {
  mode: Mode
  type: EquipmentType
  serviceId?: string
  accessorials?: EquipmentAccessorial[]
  pickupConfirmationNumber?: string
  masterTrackingNumber?: string
  declaredValue?: number
  declaredValueCurrency: Currency
  weight: number
  weightUOM: WeightUOM
  description: string
  alternateTypes: EquipmentType[]
  isHazardous: boolean
}

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import {
  MilitaryTime,
  militaryTimeFormat,
  StandardTime,
  standardTimeFormat,
  TimeOfDay,
} from '@fv/client-types'
import { DistanceUOM, Locale, locales, ProductType } from '@fv/models'

dayjs.extend(customParseFormat)

export function formatNumber(number?: number | string | null, locale?: Locale) {
  if (number === null || number === undefined) return ''

  if (typeof number === 'string') {
    number = number.replace(/\D/g, '')
    number = parseInt(number, 10)
  }

  if (isNaN(number)) return ''

  return new Intl.NumberFormat(locale ?? [...locales.map(l => l.name)]).format(
    number,
  )
}
export const stripPhoneNumberFormatting = (phoneNumber: string) => {
  const r = /\(?(\d{3})\)\s?-?(\d{3})-(\d{4})/
  const matches = phoneNumber.match(r)
  if (!matches || matches.length !== 4) {
    return phoneNumber
  }
  return matches[1] + matches[2] + matches[3]
}
export function standardToMilitaryTime(
  time: StandardTime | string,
): MilitaryTime {
  return dayjs(time, standardTimeFormat).format(
    militaryTimeFormat,
  ) as MilitaryTime
}

export function militaryToStandardTime(
  time: MilitaryTime | string,
  format = standardTimeFormat,
): StandardTime {
  return dayjs(time, militaryTimeFormat).format(format) as StandardTime
}

export function militaryTimeOfDay(time: MilitaryTime): TimeOfDay {
  return dayjs(time, militaryTimeFormat).format('A') as TimeOfDay
}

export function stripMeridiem(time: StandardTime): string {
  return time.replace(/\s(am|pm)/i, '')
}

export function createStandardTime(
  time: string,
  meridiem: TimeOfDay,
): StandardTime {
  return `${time} ${meridiem}` as StandardTime
}

export function getDistanceDisplay(
  load: {
    distance?: number
    distanceUOM?: DistanceUOM
  },
  locale?: Locale,
) {
  if (!load.distance) return null

  const distance = Math.round(load.distance)
  const uom = load.distanceUOM === 'km' ? 'kilometers' : 'miles'

  return `${formatNumber(distance, locale)} ${uom}`
}

export function formatFeatureText(feature: ProductType): string {
  return feature
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type AdminLoad } from '../types/admin-load'
import { adminFetch } from '../utils'

async function uncancelLoad(dto: { loadId: string }) {
  return adminFetch<AdminLoad>(`/loads/${dto.loadId}/cancel`, {
    method: 'DELETE',
  })
}

export function useUncancelLoad() {
  const queryClient = useQueryClient()

  const mutation = useMutation(uncancelLoad, {
    onError: (error: { message: string }) => {
      toast.error(error.message)
      mutation.reset()
    },
    onSuccess: (load: { _id: string }) => {
      queryClient.invalidateQueries(['dispatch-list'])
      queryClient.invalidateQueries(['missing-bol-list'])
      queryClient.setQueryData(['load', load._id], load)
      toast.success('Load has been uncanceled.')
      mutation.reset()
    },
  })

  return mutation
}

import { type DateRange } from '@fv/models'

import { type ColumnDef } from '../../components/shared/AdminTable'

export const timespans = [
  'hourly',
  'daily',
  'weekly',
  'monthly',
  'yearly',
] as const

export type Timespan = (typeof timespans)[number]

export type ReportName =
  | 'accounts-overview'
  | 'accounts-integrations'
  | 'accounts-by-carrier'
  | 'accounts-subscriptions'
  | 'dispatches-by-carrier'
  | 'dispatches-by-method'
  | 'dispatches-by-mode'
  | 'dispatches-fallbacks'
  | 'dispatches'
  | 'quotes-by-carrier'
  | 'quotes-by-customer'
  | 'volume-by-carrier'
  | 'volume-by-customer'
  | 'volume-by-equipment'
  | 'volume-by-mode'

export type ReportFilterParam =
  | 'carrierIds[]'
  | 'client'
  | 'modes[]'
  | 'pricingMethod'
  | 'pricingType'
  | 'product[]'
  | 'quoteMethod'
  | 'range'
  | 'timespan'
  | 'timespan-no-hour'
  | 'includeCustomerRouted'

export type ReportsPageParams = {
  reportName?: ReportName
}

export type ReportOptions = {
  endDate: string
  range: DateRange
  startDate: string
  timespan?: Timespan
}

export type ReportResult = {
  _id?: string
  bolOnly?: number
  count: number
  details?: ReportResult[]
  freightCollect?: number
  fum?: number
  id?: string
  lastSeen?: string
  ltl?: number
  name?: string
  parcel?: number
  prosFound?: number
  quoted?: number
  spend?: number
  tendered?: number
  totalRequests?: number
  truckloadQuotes?: number
  uniqueLoads?: number
  uniqueProsFound?: number
  loads?: string[]
  value: string
}

export type ReportResultsSummary = {
  results: ReportResult[]
  viewOpts: ReportOptions
}

export type ReportsComponentProps = {
  range: string
  reportName?: ReportName
  timespan?: string
}

export type ReportColumns = Array<ColumnDef<ReportResult>>

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  type AccountHistoryDto,
  type AccountNoteDto,
  type AdminAccountModel,
} from '@fv/client-types'

import { adminFetch } from '../../utils'

export function useSearchAccounts(search: string, enabled = true) {
  return useQuery(
    accountQueryKeys.search(search),
    () =>
      adminFetch<AdminAccountModel[]>(`/accounts`, {
        query: { search },
      }),
    {
      staleTime: Infinity,
      enabled,
    },
  )
}

export function useAccount(id?: string) {
  return useQuery(
    accountQueryKeys.id(id),
    () => adminFetch<AdminAccountModel>(`/accounts/${id}`),
    {
      enabled: !!id,
      refetchOnWindowFocus: false, // account has a lot of data - refetching this every time a window focus changes uses a lot of resources
    },
  )
}

export const accountQueryKeys = {
  all: ['accounts'],
  id: (id?: string) => [...accountQueryKeys.all, ...(id ? [id] : [])],
  search: (search: string) => [...accountQueryKeys.all, 'search', search],
  vendors: (id: string) => ['account-vendors', id],
}

export const useAccountHistory = (accountId?: string) => {
  return useQuery(
    ['account-history', accountId],
    () => adminFetch<AccountHistoryDto[]>(`/accounts/${accountId}/history`),
    {
      enabled: !!accountId,
    },
  )
}

export const triggerInvoiceAdded = (accountId?: string) => {
  return adminFetch(`/accounts/${accountId}/invoice-added`, { method: 'POST' })
}

export const useAccountNotes = (accountId?: string) => {
  return useQuery(
    ['account-notes', accountId],
    () => adminFetch<AccountNoteDto[]>(`/accounts/${accountId}/notes`),
    {
      enabled: !!accountId,
      select: data =>
        data.map(d => ({
          ...d,
          date_created: new Date(d.date_created),
        })),
    },
  )
}

type CreateNoteArgs = {
  accountId: string
  note: string
}
export const useCreateAccountNote = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ accountId, ...body }: CreateNoteArgs) =>
      adminFetch(`/accounts/${accountId}/notes`, { method: 'POST', body }),
    {
      onSettled(data, err, { accountId }) {
        queryClient.invalidateQueries(['account-notes', accountId])
      },
    },
  )
}

import { vDate, vObject, vOptional, vPicklist } from '../validation'

export const deliveryOptions = [
  'appointment',
  'date-certain',
  'evening',
] as const
export type DeliveryOptionType = (typeof deliveryOptions)[number]

export interface DeliveryOption {
  option: DeliveryOptionType
  date?: Date
}

export const deliveryOptionSchema = vObject({
  option: vPicklist(deliveryOptions),
  date: vOptional(vDate()),
})

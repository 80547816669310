// Regular expressions for time format validation
const TIME_24_REGEX = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/
const TIME_12_REGEX = /^(0?[1-9]|1[0-2]):([0-5][0-9])\s*(am|pm)$/i

export function standardToMilitary(timeString: string): string {
  if (!timeString) return timeString

  if (TIME_24_REGEX.test(timeString)) {
    return timeString
  }

  const match = timeString.match(TIME_12_REGEX)
  if (!match) {
    return timeString
  }

  const [_, hours, minutes, period] = match
  let hour = parseInt(hours, 10)
  if (period.toLowerCase() === 'pm' && hour !== 12) {
    hour += 12
  } else if (period.toLowerCase() === 'am' && hour === 12) {
    hour = 0
  }

  return `${hour.toString().padStart(2, '0')}:${minutes}`
}

export function militaryToStandard(timeString: string): string {
  if (!timeString) return timeString

  if (TIME_12_REGEX.test(timeString)) {
    return timeString
  }
  const match = timeString.match(TIME_24_REGEX)
  if (!match) {
    return timeString
  }

  const [_, hoursStr, minutes] = match
  let hours = parseInt(hoursStr, 10)
  const period = hours >= 12 ? 'PM' : 'AM'

  if (hours === 0) {
    hours = 12
  } else if (hours > 12) {
    hours -= 12
  }

  return `${hours}:${minutes} ${period}`
}

export const getEnvValue = (key: string) => {
  const val: string = import.meta.env[key]
  if (val?.startsWith('VITE_DEPLOY')) return undefined
  return val
}

// NOTE
// When adding values in here, make sure to add corresponding value in `.env.production` of the relevant frontend applications

export const adminUri = getEnvValue('VITE_ADMIN_URI')
export const apiUri = getEnvValue('VITE_FREIGHTVIEW_API_URI')
export const coreApiRootUrl = getEnvValue('VITE_CORE_ROOT_URI')
export const appName = getEnvValue('VITE_APPLICATION_NAME')
export const appVersion = getEnvValue('VITE_APP_VERSION')
export const marketingUri = getEnvValue('VITE_MARKETING_URI')
export const carrierUri = getEnvValue('VITE_CARRIER_URI')
export const logrocketApp = getEnvValue('VITE_LOGROCKET_APP')
export const olarkId = getEnvValue('VITE_OLARK_ID')
export const recaptchaKey = getEnvValue('VITE_RECAPTCHA_KEY')
export const shipperUri = getEnvValue('VITE_SHIPPER_URI')
export const am5Key = getEnvValue('VITE_AM5_KEY')

export const VITE_ENV = getEnvValue('VITE_ENV')
export const IS_PRODUCTION = VITE_ENV === 'production'
export const IS_STAGING = VITE_ENV === 'staging'
export const IS_SANDBOX = VITE_ENV === 'sandbox'
export const IS_DEVELOPMENT = VITE_ENV === 'development' //!IS_PRODUCTION && !IS_STAGING && !IS_SANDBOX

import { useMemo } from 'react'

import { useReportSettings } from '../features/reports/queries'

export function useCarriers() {
  const settingsQuery = useReportSettings()

  return useMemo(() => {
    if (!settingsQuery.data) return []
    return settingsQuery.data.carriers.slice().sort((a, b) => {
      const aName = a.name.trim().toLocaleLowerCase()
      const bName = b.name.trim().toLocaleLowerCase()

      return aName.localeCompare(bName)
    })
  }, [settingsQuery.data])
}

import clsx from 'clsx'

import { SliderPanel, type SliderPanelProps } from '@fv/client-components'

export const AdminSliderPanel = (
  props: SliderPanelProps & { title?: string },
) => {
  return (
    <SliderPanel
      {...props}
      className={clsx('bg-base-100 !z-[999]', props.className)}
    >
      {!!props.title && <h2 className="mb-2 text-xl">{props.title}</h2>}
      {props.children}
    </SliderPanel>
  )
}

import { useQuery } from '@tanstack/react-query'

import { type SerializedLoadOutputFormat } from '@fv/models'

import { adminFetch } from '../utils'

export type SerializeLoadArgs = {
  loadId: string
  output: SerializedLoadOutputFormat
}

async function serializeLoad({ loadId, output }: SerializeLoadArgs) {
  return adminFetch<object>(`/loads/${loadId}/serialized?output=${output}`)
}

export function useSerializeLoad(args: SerializeLoadArgs, enabled: boolean) {
  return useQuery(
    ['load', args.loadId, args.output],
    () => serializeLoad(args),
    {
      enabled,
    },
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { adminFetch } from '../../utils'

const forceRerate = (loadId: string) =>
  adminFetch(`/loads/${loadId}/rerate`, {
    method: 'POST',
  })

export function useForceRerate(loadId: string) {
  const queryClient = useQueryClient()

  return useMutation(() => forceRerate(loadId), {
    onSuccess() {
      queryClient.invalidateQueries(['load', loadId])
      toast.success('Rerate has been triggered')

      setTimeout(() => {
        queryClient.invalidateQueries(['load', loadId])
      }, 15000)
    },
    onError() {
      toast.success('Error forcing a rerate')
    },
    retry: false,
  })
}

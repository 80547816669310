import { useMutation, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import toast from 'react-hot-toast'

import {
  getPaths,
  getPathValues,
  setPathValues,
  shipperUri,
  showApiError,
} from '@fv/client-core'
import {
  type AccountLocation,
  type AccountLocationUpsertModel,
  type AccountUser,
  type AdminAccountModel,
  type AdminAccountSettingKey,
  type Flatten,
} from '@fv/client-types'
import {
  type AccountRoleType,
  type AccountSettings,
  adminAccountSettingsSchema,
  type ProductType,
} from '@fv/models'
import { toggleArrayItem } from '@fv/models/core'
import { createDefault } from '@fv/models/validation'

import { adminFetch } from '../../utils'
import { accountQueryKeys, useAccount } from './queries'

const upsertAccountLocation = ({
  accountId,
  body,
  locationId,
}: {
  accountId: string
  body: AccountLocationUpsertModel
  locationId?: string
}) =>
  adminFetch<AccountLocation>(
    `/accounts/${accountId}/locations${locationId ? `/${locationId}` : ''}`,
    {
      body: { ...body, country: 'us' },
      method: 'PUT',
    },
  )

export function useUpsertAccountLocation() {
  const queryClient = useQueryClient()
  return useMutation(upsertAccountLocation, {
    onSuccess(data, { locationId, accountId }) {
      toast.success(locationId ? 'Updated Location' : 'Created Location')
      queryClient.invalidateQueries(accountQueryKeys.id(accountId))
    },
    onError() {
      toast.error('Error saving location')
    },
  })
}

const delAcctLocation = ({
  accountId,
  locationId,
}: {
  accountId: string
  locationId: string
}) =>
  adminFetch(`/accounts/${accountId}/locations/${locationId}`, {
    method: 'DELETE',
  })

export function useDeleteAccountLocation() {
  const queryClient = useQueryClient()
  return useMutation(delAcctLocation, {
    onSuccess(data, { accountId }) {
      toast.success('Removed Location')
      queryClient.invalidateQueries(accountQueryKeys.id(accountId))
    },
    onError() {
      toast.error('Error removing location')
    },
  })
}

type UnlockArgs = {
  accountId: string
  userId: string
}
export const useUnlockUser = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ accountId, userId }: UnlockArgs) =>
      adminFetch(`/accounts/${accountId}/users/${userId}/unlock`, {
        method: 'PUT',
      }),
    {
      onError: err => {
        showApiError('There was an error unlocking the user', err)
      },
      onSuccess: (resp, { accountId }) => {
        toast.success('Unlocked User')
        queryClient.invalidateQueries(accountQueryKeys.id(accountId))
      },
    },
  )
}

type FreeUpArgs = {
  accountId: string
  userId: string
}
export const useFreeUpEmail = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ accountId, userId }: FreeUpArgs) =>
      adminFetch(`/accounts/${accountId}/users/${userId}/free-up-email`, {
        method: 'PUT',
      }),
    {
      onError: err => {
        showApiError('There was an error freeing up the email address', err)
      },
      onSuccess: (resp, { accountId }) => {
        toast.success('Changed email')
        queryClient.invalidateQueries(accountQueryKeys.id(accountId))
      },
    },
  )
}
type AccountSettingsMutation = {
  model: AccountSettings
  updates: Partial<Flatten<AccountSettings>>
}

const executeMutation = (mutation: AccountSettingsMutation) => {
  const model = cloneDeep(mutation.model)
  setPathValues(model, mutation.updates)
  return model
}

const getCurrentValues = (
  model: AccountSettings | undefined,
  updates: Partial<Flatten<AccountSettings>>,
): Partial<Flatten<AccountSettings>> | undefined =>
  model && getPathValues(model, getPaths(updates))

const accountSettingsPut = async ({
  id,
  mutation,
}: {
  id: string
  mutation: AccountSettingsMutation
}): Promise<AccountSettings> => {
  const reqModel = executeMutation(mutation)

  return await adminFetch(`/accounts/${id}/settings`, {
    body: reqModel,
    method: 'PUT',
  })
}

export const useAccountSettingMutation = (
  id: string,
  model: AccountSettings,
) => {
  const queryClient = useQueryClient()
  const queryKey = accountQueryKeys.id(id)
  const mutation = useMutation(accountSettingsPut, {
    onMutate({ mutation }) {
      const previousValue =
        queryClient.getQueryData<AdminAccountModel>(queryKey)

      queryClient.setQueryData<AdminAccountModel>(queryKey, prev => {
        //optimistic update
        return (
          prev && {
            ...prev,
            settings: executeMutation({
              ...mutation,
              model: prev.settings ?? createDefault(adminAccountSettingsSchema),
            }),
          }
        )
      })
      return getCurrentValues(previousValue?.settings, mutation.updates)
    },
    onError(e, data, context) {
      if (context)
        queryClient.setQueryData<AdminAccountModel>( //revert optimistic update
          queryKey,
          prev =>
            prev && {
              ...prev,
              settings: executeMutation({
                model:
                  prev.settings ?? createDefault(adminAccountSettingsSchema),
                updates: context,
              }),
            },
        )
    },
    onSettled(_data, error: any) {
      if (error) {
        toast.error(`Unable to update setting ${error.message}`)
      } else {
        toast.success('Setting updated successfully.')
      }
    },
  })

  const updateAccountSetting = <TValue>(
    key: AdminAccountSettingKey,
    value: TValue,
  ) => {
    if (!id) {
      return
    }

    mutation.mutate({
      id,
      mutation: {
        model,
        updates: {
          [key]: value,
        },
      },
    })
  }

  return {
    isBusy: mutation.isLoading,
    updateAccountSetting,
  }
}

type ToggleAccountProductPayload = {
  feature: ProductType
  enable: boolean
}
const toggleAccountProduct =
  (accountId: string) =>
  async ({ feature, enable }: ToggleAccountProductPayload) => {
    return adminFetch<ProductType[]>(
      `/accounts/${accountId}/features/${feature}`,
      {
        method: enable ? 'POST' : 'DELETE',
      },
    )
  }
export const useToggleAccountProduct = (accountId: string) => {
  const queryClient = useQueryClient()
  const { data: account } = useAccount(accountId)
  return useMutation(toggleAccountProduct(accountId), {
    onMutate({ feature, enable }) {
      queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
        ...(account ?? {}),
        products: toggleArrayItem(account?.products ?? [], feature, enable),
      }))
    },
    onSettled(result, error, { feature, enable }) {
      if (error) {
        showApiError(`Could not edit products`, error)

        // revert optimistic
        queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
          ...(account ?? {}),
          products: toggleArrayItem(account?.products ?? [], feature, !enable),
        }))
      }
    },
  })
}

type ToggleAccountRolePayload = {
  role: AccountRoleType
  enable: boolean
}
const toggleAccountRole =
  (accountId: string) =>
  async ({ role, enable }: ToggleAccountRolePayload) => {
    return adminFetch<AccountRoleType[]>(
      `/accounts/${accountId}/roles/${role}`,
      {
        method: enable ? 'POST' : 'DELETE',
      },
    )
  }
export const useToggleAccountRole = (accountId: string) => {
  const queryClient = useQueryClient()
  const { data: account } = useAccount(accountId)
  return useMutation(toggleAccountRole(accountId), {
    onMutate({ role, enable }) {
      queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
        ...(account ?? {}),
        roles: toggleArrayItem(account?.roles ?? [], role, enable),
      }))
    },
    onSettled(result, error, { role, enable }) {
      if (error) {
        showApiError(`Could not edit roles`, error)

        // revert optimistic
        queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
          ...(account ?? {}),
          roles: toggleArrayItem(account?.roles ?? [], role, !enable),
        }))
      }
    },
  })
}

const toggleFlexBillTo = (accountId: string) => async (enable: boolean) => {
  return adminFetch(`/accounts/${accountId}/toggle-flex-bill-to/`, {
    method: 'POST',
    body: {
      enable,
    },
  })
}
export const useToggleFlexBillTo = (accountId: string) => {
  const queryClient = useQueryClient()
  const { data: account } = useAccount(accountId)
  return useMutation(toggleFlexBillTo(accountId), {
    onMutate(enable) {
      queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
        ...(account ?? {}),
        features: {
          flexBillTo: enable,
        },
      }))
    },
    onSettled(result, error, enable) {
      if (error) {
        showApiError(`Could not edit flex bill-to`, error)

        // revert optimistic
        queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
          ...(account ?? {}),
          features: {
            flexBillTo: !enable,
          },
        }))
      }
    },
  })
}

export const useSimulateAccountSetup = (accountId: string) => {
  return useMutation(() =>
    adminFetch(`/accounts/${accountId}/simulate-setup`, {
      method: 'POST',
    }),
  )
}

export const useSyncWithClose = (accountId: string) => {
  return useMutation(
    () => adminFetch(`/accounts/${accountId}/crm-sync`, { method: 'POST' }),
    {
      onError: err => showApiError(`Unable to queue sync with Close.io`, err),
      onSuccess: () =>
        toast.success('Account queued for syncing with Close.io'),
    },
  )
}

export const useVerifyAccount = (accountId: string) => {
  return useMutation(
    () => adminFetch(`/accounts/${accountId}/verify`, { method: 'POST' }),
    {
      onError: err => showApiError(`Unable to verify account`, err),
      onSuccess: () => toast.success('Account verified'),
    },
  )
}

export const useToggleAccount = (accountId: string) => {
  const queryClient = useQueryClient()
  const { data: account } = useAccount(accountId)

  return useMutation(
    (disabled: boolean) =>
      adminFetch(`/accounts/${accountId}/toggle`, {
        method: 'POST',
        body: {
          disabled,
        },
      }),
    {
      onMutate(disabled) {
        queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
          ...(account ?? {}),
          disabled,
        }))
      },
      onError: err => showApiError(`Unable to toggle account`, err),
      onSuccess: (_result, disabled) =>
        toast.success(`Account ${disabled ? 'disabled' : 'enabled'}`),
    },
  )
}

type ToggleMarketingArgs = {
  userId: string
  subscribed: boolean
}
export const useToggleMarketing = (accountId: string) => {
  const queryClient = useQueryClient()
  const { data: account } = useAccount(accountId)
  return useMutation(
    ({ userId, subscribed }: ToggleMarketingArgs) =>
      adminFetch(`/accounts/${accountId}/users/${userId}/marketing`, {
        method: 'PUT',
        body: {
          subscribed,
        },
      }),
    {
      onMutate({ userId, subscribed }) {
        queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
          ...(account ?? {}),
          users: account.users.map(u => ({
            ...u,
            marketing: {
              subscribed:
                u._id === userId ? subscribed : u.marketing?.subscribed,
            },
          })),
        }))
      },
      onSettled(result, error, { userId, subscribed }) {
        if (error) {
          showApiError(`Could not edit update marketing subscription`, error)
          // revert optimistic
          queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
            ...(account ?? {}),
            users: account.users.map(u => ({
              ...u,
              marketing: {
                subscribed:
                  u._id === userId ? !subscribed : u.marketing?.subscribed,
              },
            })),
          }))
        }
      },
    },
  )
}

export const useSetOwner = (accountId: string) => {
  const queryClient = useQueryClient()
  const { data: account } = useAccount(accountId)
  return useMutation(
    (userId: string) =>
      adminFetch<AccountUser[]>(`/accounts/${accountId}/users/owner`, {
        method: 'PUT',
        body: {
          userId,
        },
      }),
    {
      onSuccess(users) {
        queryClient.setQueryData(accountQueryKeys.id(accountId), () => ({
          ...(account ?? {}),
          users,
        }))
      },
      onError(error) {
        showApiError(`Could not make user owner`, error)
      },
    },
  )
}

type ViewAsProps = {
  userId: string
  accountId: string
  redirect?: string
}

export const useViewAsShipper = () => {
  return useMutation(
    ({ userId, accountId }: ViewAsProps) =>
      adminFetch<AccountUser[]>(
        `/accounts/${accountId}/users/${userId}/view-as`,
        {
          method: 'POST',
        },
      ),
    {
      onSuccess(_response, { redirect }) {
        window.open(
          `${shipperUri}/${redirect || ''}`,
          '_blank',
          'noopener,noreferrer',
        )
      },
      onError(error) {
        showApiError(`Could not view as user`, error)
      },
    },
  )
}

export const useRemoveVendor = (accountId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (vendorId: string) =>
      adminFetch(`/accounts/${accountId}/vendors/${vendorId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries(accountQueryKeys.vendors(accountId))
      },
      onError(error) {
        showApiError(`Could not delete vendor`, error)
      },
    },
  )
}

import clsx from 'clsx'
import { useRef } from 'react'
import toast from 'react-hot-toast'

import { ValidatedForm } from '@fv/client-components'
import { acceptedFileTypes } from '@fv/client-core'
import { type PropsWithClassName } from '@fv/client-types'
import { type LoadDocumentSource, type LoadDocumentType } from '@fv/models'

import { AdminButton } from '../../components/shared/AdminButton'
import { AdminFileInput } from '../../components/shared/AdminFileInput'
import { AdminFormGroup } from '../../components/shared/AdminFormGroup'
import { AdminSelect } from '../../components/shared/AdminSelect'
import { maxFileSize } from '../../constants'
import { useUploadLoadDocument } from '../dispatches/mutations'

const docSourceOptions: Array<{ text: string; value: LoadDocumentSource }> = [
  { text: 'Carrier', value: 'carrier' },
  { text: 'System', value: 'system' },
  { text: 'Shipper', value: 'shipper' },
]

const docTypeOptions: Array<{ text: string; value: LoadDocumentType }> = [
  { text: 'BOL', value: 'bol' },
  { text: 'Invoice', value: 'invoice' },
  { text: 'Proof of Delivery', value: 'proof-of-delivery' },
  { text: 'Signed BOL', value: 'signed-bol' },
]

type Props = PropsWithClassName<{
  loadId: string
  onSuccess?: () => void
}>

export const UploadDocumentForm = ({ className, loadId, onSuccess }: Props) => {
  const uploadDocument = useUploadLoadDocument()
  const docRef = useRef<HTMLInputElement>(null)
  const docSourceRef = useRef<HTMLSelectElement>(null)
  const docTypeRef = useRef<HTMLSelectElement>(null)

  const onSubmit = () => {
    if (!docRef.current?.files?.length) {
      toast.error('Please select a document.')
      return
    }

    const [file] = Array.from(docRef.current.files)
    const source = docSourceRef.current?.value as LoadDocumentSource
    const type = docTypeRef.current?.value as LoadDocumentType

    if (file.size > maxFileSize) {
      docRef.current.value = ''
      toast.error(`Max file size is ${maxFileSize / 1024}KB`)
      return
    }

    uploadDocument
      .mutateAsync({
        file,
        loadId,
        source,
        type,
      })
      .then(() => onSuccess?.())
  }

  return (
    <ValidatedForm
      onValidSubmit={() => onSubmit()}
      className={clsx('max-w-xxl m-auto grid grid-cols-2 gap-4', className)}
    >
      <AdminFormGroup label="" className="col-span-2">
        <AdminFileInput
          disabled={uploadDocument.isLoading}
          name="file"
          ref={docRef}
          id="loadDocumentFile"
          accept={acceptedFileTypes}
        />
      </AdminFormGroup>

      <AdminFormGroup label="Type">
        <AdminSelect
          options={docTypeOptions}
          name="type"
          defaultValue="bol"
          className="w-full"
          ref={docTypeRef}
        />
      </AdminFormGroup>

      <AdminFormGroup label="Source">
        <AdminSelect
          options={docSourceOptions}
          name="source"
          defaultValue="carrier"
          label="Source"
          className="w-full"
          ref={docSourceRef}
        />
      </AdminFormGroup>
      <div className="col-span-2 text-right">
        <AdminButton
          type="submit"
          className="btn btn-primary"
          loading={uploadDocument.isLoading}
        >
          Submit
        </AdminButton>
      </div>
    </ValidatedForm>
  )
}

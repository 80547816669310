import invert from 'lodash/invert'

import { capitalizeFirstLetter } from '../core'
import {
  LegacyShipType,
  LocationAccessorialDTO,
  locationAccessorials,
  ShipType,
} from './location-accessorial'

const shipTypeMap: { [k in ShipType]: LegacyShipType } = {
  'business-dock': 'business dock',
  'business-no-dock': 'business no dock',
  'construction-site': 'construction',
  'limited-access': 'limited access',
  'place-of-worship': 'place of worship',
  tradeshow: 'trade show',
  airport: 'airport',
  farm: 'farm',
  military: 'military',
  mine: 'mine',
  pier: 'pier',
  residential: 'residential',
  school: 'school',
  prison: 'prison',
}

const legacyMap = invert(shipTypeMap) as {
  [k in LegacyShipType]: ShipType
}

export const ShipTypeMapper = {
  coreToLegacy(st?: ShipType): LegacyShipType | undefined {
    return shipTypeMap[st]
  },

  legacyToCore(st?: LegacyShipType): ShipType | undefined {
    return legacyMap[st]
  },

  display(st?: LegacyShipType, showBusinessType = true): string | undefined {
    if (!showBusinessType && /business/.test(st)) {
      return ''
    }

    switch (st) {
      case 'construction':
        return 'Construction site'
      case 'limited access':
        return 'Limited access'
      case 'place of worship':
        return 'Place of worship'
      case 'trade show':
        return 'Tradeshow'
      case 'military':
        return 'Government/Military'
      default:
        return capitalizeFirstLetter(st)
    }
  },

  displayLocShipType({
    shipType,
    accessorials,
  }: {
    shipType?: LegacyShipType
    accessorials?: LocationAccessorialDTO[]
  }) {
    if (
      !shipType ||
      shipType === 'business dock' ||
      shipType === 'business no dock'
    ) {
      return undefined
    }

    const coreShip = ShipTypeMapper.legacyToCore(shipType)
    const shipName = locationAccessorials.find(a => a.key === coreShip)?.name

    if (coreShip === 'tradeshow') {
      const booth = accessorials.find(
        a => a.key === 'tradeshow',
      )?.tradeshowBooth
      if (booth) {
        return `${shipName} (Booth ${booth})`
      }
    }
    return coreShip &&
      coreShip !== 'business-dock' &&
      coreShip !== 'business-no-dock'
      ? shipName
      : undefined
  },
}

import { ObjectId } from '@freightview/object-id'

import {
  vArray,
  vBoolean,
  vCountryAny,
  vCountryAnyToUpper,
  vEmail,
  vNonEmptyString,
  vNumber,
  vObject,
  vOptional,
  vOptionalDef,
  vOutput,
  vPhoneExt,
  vPhoneNumber,
  vPicklist,
  vPostalCode,
  vStandardTime,
  vString,
} from '../validation'
import { ChargeName } from './charge'
import { Country, LegacyCountry } from './country'
import {
  LegacyShipType,
  legacyShipTypes,
  locationAccessorialSchema,
  LocationAccessorialType,
} from './location-accessorial'

export interface AddressBase {
  address: string
  address2?: string
  adminArea3?: string
  city: string
  state: string
  postalCode: string
  country: Country
}

export interface LegacyAddressBase extends Omit<AddressBase, 'country'> {
  country: LegacyCountry
}

export interface CompanyAddressBase extends AddressBase {
  company?: string
}

export const addressBaseSchema = vObject({
  address: vNonEmptyString(),
  address2: vOptional(vString()),
  adminArea3: vOptional(vString()),
  city: vNonEmptyString(),
  state: vNonEmptyString(),
  postalCode: vPostalCode(),
  country: vCountryAny,
})

export interface LocationAccessorial {
  key: LocationAccessorialType
}

const addressSources = ['dispatch', 'manual', 'import'] as const
const addressTypes = ['billing', 'shipping'] as const

export type AddressType = (typeof addressTypes)[number]

export interface AddressModel extends Omit<AddressBase, 'country'> {
  _id: ObjectId
  accountId: ObjectId
  vendorId?: ObjectId
  locationId?: ObjectId // account location id
  created: Date
  accountLocation: boolean
  company: string
  contactName?: string
  contactEmail?: string
  contactPhone?: string
  contactPhoneExt?: string
  timezone: string
  country: LegacyCountry
  bolFollowers: string[]
  charges: Array<{ name: ChargeName }>
  accessorials?: LocationAccessorial[]
  dockHoursStart?: string
  dockHoursEnd?: string
  instructions: string
  lat: number
  lng: number
  sendBOL?: boolean
  shipType: LegacyShipType
  source: (typeof addressSources)[number]
  type: AddressType
  lastUpdated?: Date
  lastUsed?: Date
}

export const mappedAccessorialsSchema = vObject({
  ltl: vArray(locationAccessorialSchema),
  parcel: vArray(locationAccessorialSchema),
  truckload: vArray(locationAccessorialSchema),
})

export type MappedAccessorials = vOutput<typeof mappedAccessorialsSchema>

export const addressUpsertSchema = vObject({
  ...addressBaseSchema.entries,
  country: vCountryAnyToUpper(),
  company: vNonEmptyString(),
  contactName: vOptional(vString()),
  contactEmail: vOptional(vEmail),
  contactPhone: vOptional(vPhoneNumber()),
  contactPhoneExt: vOptional(vPhoneExt()),
  dockHoursStart: vOptional(vStandardTime()),
  dockHoursEnd: vOptional(vStandardTime()),
  instructions: vOptional(vString()),
  lat: vOptional(vNumber()),
  lng: vOptional(vNumber()),
  bolFollowers: vOptional(vArray(vString())),
  sendBOL: vOptionalDef(vBoolean(), false),
  shipType: vPicklist(legacyShipTypes),
  source: vOptional(vPicklist(addressSources)),
  type: vOptional(vPicklist(addressTypes)),
  timezone: vOptional(vString()),
  mappedAccessorials: mappedAccessorialsSchema,
})
export type AddressUpsertDTO = vOutput<typeof addressUpsertSchema>

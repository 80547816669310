import { DTO } from '../core'
import {
  vBoolean,
  vDate,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import { workflows } from './common'
import { loadStatuses } from './load'

export const adminLoadSearchSchema = vObject({
  accountId: vOptional(vString()),
  carrierId: vOptional(vString()),
  search: vOptional(vString()),
  pickupDateStart: vOptional(vDate()),
  pickupDateEnd: vOptional(vDate()),
  bookedDateStart: vOptional(vDate()),
  bookedDateEnd: vOptional(vDate()),
  createdDateStart: vOptional(vDate()),
  createdDateEnd: vOptional(vDate()),
  sort: vOptional(vPicklist(['createdDate', 'pickupDate', 'bookedDate'])),
  sortDir: vOptional(vPicklist(['asc', 'desc'])),
  status: vOptional(vPicklist([...loadStatuses])),
  workflow: vOptional(vPicklist([...workflows])),
  freightCollect: vOptional(vBoolean()),
})

export type AdminLoadSearchQuery = vOutput<typeof adminLoadSearchSchema>
export type AdminLoadSearchDTO = DTO<AdminLoadSearchQuery>

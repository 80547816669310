import { ObjectId } from '@freightview/object-id'

import {
  vArray,
  vCurrency,
  vMinValue,
  vNumber,
  vObject,
  vObjectId,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vString,
} from '../validation'
import { ChargeName, chargeNames } from './charge'
import {
  Currency,
  Mode,
  PaymentTerms,
  PricingMethod,
  PricingType,
  QuoteMethodType,
  QuoteSourceType,
  QuoteStatus,
} from './common'
import { EquipmentType } from './equipment-type'

export interface QuoteDTO {
  _id: ObjectId
  amount: number
  assetCarrierCode?: string
  assetCarrierName?: string
  carrierId: string
  charges?: ApiChargeDTO[]
  createdDate: Date
  currency: Currency
  deliveryDate?: Date
  equipmentType: EquipmentType
  errors?: ApiQuoteError[]
  expirationDate?: Date | null
  guaranteed?: boolean
  interline?: boolean
  isLegacy?: boolean
  isSpot?: boolean
  method: QuoteMethodType
  mode: Mode
  notes?: string[]
  paymentTerms?: PaymentTerms
  pickupDate?: Date
  pricingDescription?: string
  pricingMethod: PricingMethod
  pricingType: PricingType
  providerCode?: string
  providerName: string
  quotedBy?: ApiQuotedByDTO
  quoteNum?: string | null
  refNums?: Array<{ type: string; value: string }>
  serviceDescription?: string
  serviceId: string
  source: QuoteSourceType
  status: QuoteStatus
  terminals?: ApiQuoteTerminal[]
  transitDaysMax?: number
  transitDaysMin?: number
  accountNumber?: string
  hasMarkup?: boolean
  loadQuoteVersion?: number
  endOfDay?: boolean
  scrape?: boolean
}
interface ApiQuoteError {
  type: string
  message: string
}
export interface ApiChargeDTO {
  name: ChargeName
  amount: number
  type?: string
  code?: string
  currency: Currency
  providerDetail?: string
}
interface ApiQuotedByDTO {
  userId: string
  name: string
}

interface ApiQuoteTerminal {
  terminalId: string
  terminalType: TerminalType
}
export type TerminalType = 'origin terminal' | 'destination terminal'

export const updateChargeSchema = vObject({
  name: vPicklist([...chargeNames]),
  amount: vPipe(vNumber(), vMinValue(1)),
  providerDetail: vOptional(vString()),
  currency: vCurrency('usd'),
})
export type UpsertChargeDTO = vOutput<typeof updateChargeSchema>

export const updateQuoteSchema = vObject({
  quoteId: vObjectId(),
  amount: vPipe(vNumber(), vMinValue(0)),
  currency: vCurrency('usd'),
  charges: vOptional(vArray(updateChargeSchema)),
})

export type UpdateQuoteDTO = vOutput<typeof updateQuoteSchema>

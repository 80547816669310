import { useQuery } from '@tanstack/react-query'
import { type Tickets } from 'node-zendesk'

import { adminFetch } from '../utils'

export function useLoadTickets(loadId: string, enabled: boolean) {
  return useQuery(
    ['load', loadId, 'tickets'],
    async () =>
      await adminFetch<Tickets.ResponseModel[]>(`/loads/${loadId}/tickets`),
    {
      enabled: !!loadId && enabled,
    },
  )
}

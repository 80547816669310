import clsx from 'clsx'
import { type ComponentProps, type ForwardedRef, forwardRef } from 'react'

type FileInputProps = ComponentProps<'input'>

export const AdminFileInput = forwardRef(
  (
    { className, ...props }: FileInputProps,
    ref?: ForwardedRef<HTMLInputElement | null>,
  ) => {
    return (
      <input
        ref={ref}
        type="file"
        className={clsx('file-input file-input-bordered w-full', className)}
        {...props}
      />
    )
  },
)

import { vFallback, vObject, vStringToNumber } from '../validation'

export const paginationSchema = vObject({
  skip: vStringToNumber(),
  limit: vStringToNumber(),
})

export const createPaginationSchema = (defaults: {
  skip?: number
  limit?: number
}) =>
  vObject({
    skip: vFallback(vStringToNumber(), defaults.skip ?? 0),
    limit: vFallback(vStringToNumber(), defaults.limit ?? 20),
  })

export interface PagedResponse<T> {
  data: T[]
  continuationToken?: string
  hasMore?: boolean
}

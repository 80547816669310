import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { type FvLinkButtonProps } from '@fv/client-components'
import { AdminLink } from '@/components/shared/AdminLink'

import { useViewAsShipper } from '../mutations'

type Props = FvLinkButtonProps & {
  user: { username: string; _id: string }
  accountId: string
  className?: string
  redirect?: string
}

export const ShipperViewAsLink = ({
  user,
  accountId,
  className,
  children,
  redirect,
}: PropsWithChildren<Props>) => {
  const viewAs = useViewAsShipper()

  return (
    <AdminLink
      className={clsx(className)}
      onClick={() => {
        viewAs.mutate({
          accountId,
          userId: user._id,
          redirect,
        })
      }}
    >
      {children ? children : <>View as {user.username}</>}
    </AdminLink>
  )
}

import {
  vArray,
  vDate,
  vMinValue,
  vNumber,
  vObject,
  vObjectId,
  vOmit,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
} from '../validation'

const fuelTableEntrySchema = vObject({
  calculationType: vPicklist(['per-mile']),
  currency: vPicklist(['usd']),
  lowerBoundary: vPipe(vNumber(), vMinValue(0)),
  upperBoundary: vPipe(vNumber(), vMinValue(0)),
  rate: vNumber(),
})

export const fuelTableSchema = vObject({
  _id: vObjectId(),
  accountId: vObjectId(),
  addedById: vObjectId(),
  carrierId: vOptional(vObjectId()),
  createdDate: vDate(),
  rows: vArray(fuelTableEntrySchema),
})

export type FuelTable = vOutput<typeof fuelTableSchema>

export const addFuelTableSchema = vOmit(fuelTableSchema, [
  '_id',
  'accountId',
  'addedById',
  'createdDate',
])

export type AddFuelTableDTO = vOutput<typeof addFuelTableSchema>

export type ParsedFuelTableRow = {
  calculationType?: string
  currency?: string
  lowerBoundary?: number
  rate?: number
  upperBoundary?: number
}

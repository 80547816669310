import './InfoBox.scss'

import { type ReactNode } from 'react'

import { Icon, type IconName } from '@fv/client-components'

type InfoBoxProps = {
  children?: ReactNode
  icon?: IconName
  header?: string
  Actions?: () => JSX.Element
  noIcon?: boolean
}

const InfoBox = ({
  children,
  header,
  icon = 'hand-paper',
  noIcon,
  Actions,
}: InfoBoxProps) => {
  return (
    <div className="InfoBox">
      {header && (
        <div className="flex flex-col">
          <div className="flex flex-row items-baseline">
            <h5 className="mb-0 flex">
              {!noIcon && <Icon className="text-accent mr-2" icon={icon} />}{' '}
              {header}
            </h5>
            {Actions && (
              <div className="ml-2">
                <Actions />
              </div>
            )}
          </div>
          {children}
        </div>
      )}

      {!header && (
        <>
          {!noIcon && <Icon className="mr-2" icon={icon} />} {children}
        </>
      )}
    </div>
  )
}

export default InfoBox

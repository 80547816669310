import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  showApiError,
} from '@fv/client-core'
import {
  type LoadDocumentSource,
  type LoadDocumentType,
  type ProcessStatus,
} from '@fv/models'

import { apiUri } from '../../constants'
import { type AdminLoad } from '../../types/admin-load'
import { adminFetch } from '../../utils'

type Load = {
  dispatch?: {
    errors?: Array<{ rawMessage?: string }>
    status: ProcessStatus
  }
}

async function dispatchLoads(dto: {
  loadIds: string[]
  methodOverride?: 'api' | 'email'
}): Promise<{ errors: string[]; errorCount: number; successCount: number }> {
  const options = buildFetchOptionsWithAuth({
    ...(dto.methodOverride && {
      body: JSON.stringify({
        method: dto.methodOverride,
      }),
    }),
    method: 'POST',
  })

  const responses = await Promise.all(
    dto.loadIds.map(loadId => {
      const endpoint = `${apiUri}/loads/${loadId}/dispatch`
      return fetchJson(endpoint, options)
    }),
  )

  if (responses.every(res => !res.ok)) {
    const errorResponse = responses.find(res => !res.ok)
    throw errorResponse?.errorMessage || 'Unknown error occurred.'
  }

  const loads: Load[] = responses.filter(res => res.ok).map(res => res.json)
  const errors: string[] = responses
    .filter(res => !res.ok)
    .map(res => res.errorMessage?.message ?? 'Unknown error occurred.')

  let errorCount = errors.length
  let successCount = loads.length

  loads.forEach(load => {
    if (load.dispatch?.status === 'error') {
      errorCount++
      successCount--

      errors.push(
        load.dispatch.errors?.[0]?.rawMessage ??
          'An unknown error occured while dispatching.',
      )
    }
  })

  return { errors, errorCount, successCount }
}

export function useDispatchLoads() {
  const queryClient = useQueryClient()

  const mutation = useMutation(dispatchLoads, {
    retry: false,
    onError: (error: { message: string }) => {
      toast.error(error.message)
      mutation.reset()
    },
    onSuccess: ({ errors, errorCount, successCount }) => {
      queryClient.invalidateQueries(['dispatch-list'])
      queryClient.invalidateQueries(['missing-bol-list'])

      if (successCount > 0) {
        toast.success(
          `${successCount} load${
            successCount > 1 ? 's' : ''
          } dispatched successfully!`,
        )
      }

      if (errorCount > 0) {
        toast.error(
          `${errorCount} dispatch${
            errorCount > 1 ? 'es' : ''
          } failed: ${errors.join(', ')}`,
        )
      }

      mutation.reset()
    },
  })

  return mutation
}

async function generateBOLs(
  loadIds: string[],
): Promise<{ errors: string[]; errorCount: number; successCount: number }> {
  const options = buildFetchOptionsWithAuth({ method: 'POST' })
  const responses = await Promise.all(
    loadIds.map(loadId => {
      const endpoint = `${apiUri}/loads/${loadId}/bol`
      return fetchJson(endpoint, options)
    }),
  )

  if (responses.every(res => !res.ok)) {
    const errorResponse = responses.find(res => !res.ok)
    throw errorResponse?.errorMessage || 'Unknown error occurred.'
  }

  const loads: Load[] = responses.filter(res => res.ok).map(res => res.json)
  const errors: string[] = responses
    .filter(res => !res.ok)
    .map(res => res.errorMessage?.message ?? 'Uknown error occurred.')

  return {
    errors,
    errorCount: errors.length,
    successCount: loads.length,
  }
}

export function useGenerateBOLs() {
  const queryClient = useQueryClient()

  const mutation = useMutation(generateBOLs, {
    onError: (error: { message: string }) => {
      toast.error(error.message)
      mutation.reset()
    },
    onSuccess: ({ errors, errorCount, successCount }) => {
      queryClient.invalidateQueries(['missing-bol-list'])
      queryClient.invalidateQueries(['dispatch-list'])

      if (successCount > 0) {
        toast.success(
          `${successCount} BOL${
            successCount > 1 ? 's' : ''
          } generated successfully!`,
        )
      }

      if (errorCount > 0) {
        toast.error(
          `${errorCount} BOL${errorCount > 1 ? 's' : ''} failed: ${errors.join(
            ', ',
          )}`,
        )
      }

      mutation.reset()
    },
  })

  return mutation
}

async function internalDispatch(dto: {
  confirmationNumber: string
  loadId: string
  trackingNumber?: string
}) {
  const loadId = dto.loadId
  let { confirmationNumber, trackingNumber } = dto

  confirmationNumber = confirmationNumber.trim()
  trackingNumber = trackingNumber?.trim()

  return adminFetch(`/loads/${loadId}/dispatch-internal`, {
    body: {
      confirmationNumber,
      ...(trackingNumber && { trackingNumber }),
    },
    method: 'POST',
  })
}

export function useInternalDispatch() {
  const queryClient = useQueryClient()

  const mutation = useMutation(internalDispatch, {
    onError: (error: { message: string }) => {
      toast.error(error.message)
      mutation.reset()
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['dispatch-list'])
      queryClient.invalidateQueries(['missing-bol-list'])
      toast.success('Load marked as dispatched.')
      mutation.reset()
    },
  })

  return mutation
}

async function updateTrackingNumber(dto: {
  loadId: string
  trackingNumber: string
}) {
  return adminFetch(`/loads/${dto.loadId}/tracking-number`, {
    body: { trackingNumber: dto.trackingNumber.trim() },
    method: 'PUT',
  })
}

export function useUpdateTrackingNumber(loadId: string) {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (trackingNumber: string) =>
      updateTrackingNumber({ loadId, trackingNumber }),
    {
      onError: (error: { message: string }) => {
        toast.error(error.message)
        mutation.reset()
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['dispatch-list'])
        queryClient.invalidateQueries(['missing-bol-list'])
        queryClient.invalidateQueries(['load', loadId])
        toast.success('Tracking number updated.')
        mutation.reset()
      },
    },
  )

  return mutation
}

async function confirmPickup(dto: {
  confirmationNumber: string
  loadId: string
}) {
  return adminFetch(`/loads/${dto.loadId}/confirm-pickup`, {
    body: { confirmationNumber: dto.confirmationNumber.trim() },
    method: 'POST',
  })
}

export function useConfirmPickup(loadId: string) {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (confirmationNumber: string) =>
      confirmPickup({ loadId, confirmationNumber }),
    {
      onError: (error: { message: string }) => {
        toast.error(error.message)
        mutation.reset()
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['dispatch-list'])
        queryClient.invalidateQueries(['missing-bol-list'])
        toast.success('Pickup confirmed.')
        mutation.reset()
      },
    },
  )

  return mutation
}

async function addIrisDispatch(dto: { irisPayload: string; loadId: string }) {
  return adminFetch(`/loads/${dto.loadId}/iris-dispatch`, {
    body: JSON.parse(dto.irisPayload),
    method: 'POST',
  })
}

export function useAddIrisDispatch() {
  const queryClient = useQueryClient()

  const mutation = useMutation(addIrisDispatch, {
    onError: (error: { message: string }) => {
      toast.error(error.message)
      mutation.reset()
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['dispatch-list'])
      queryClient.invalidateQueries(['missing-bol-list'])
      toast.success('Iris dispatch applied')
      mutation.reset()
    },
  })

  return mutation
}

export function useAddDispatchError() {
  const queryClient = useQueryClient()
  return useMutation(
    ({ loadId, message }: { loadId: string; message: string }) =>
      adminFetch(`/loads/${loadId}/dispatch-error`, {
        method: 'POST',
        body: { errorMessage: message },
      }),
    {
      onError() {
        toast.error('Could not add error')
      },
      onSuccess() {
        toast.success('Set load dispatch to error')
      },
      onSettled() {
        queryClient.invalidateQueries(['dispatch-list'])
      },
    },
  )
}

async function uploadLoadDocument(dto: {
  file: string | Blob
  loadId: string
  source: LoadDocumentSource
  type: LoadDocumentType
}) {
  const endpoint = `${apiUri}/loads/${dto.loadId}/documents`
  const formData = new FormData()
  formData.append('file', dto.file)

  const options = buildFetchOptionsWithAuth({
    body: formData,
    headers: {
      'x-document-source': dto.source,
      'x-document-type': dto.type,
    },
    method: 'POST',
  })

  // Content-type set automatically from `formData`
  delete options.headers['Content-type']

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUploadLoadDocument() {
  const queryClient = useQueryClient()

  const mutation = useMutation(uploadLoadDocument, {
    onError: (error: { message: string }) => {
      toast.error(error.message)
      mutation.reset()
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['missing-bol-list'])
      queryClient.invalidateQueries(['dispatch-list'])
      toast.success('Document uploaded successfully.')
      mutation.reset()
    },
  })

  return mutation
}

async function deleteLoadDocument(dto: {
  documentId: string
  loadId: string
}): Promise<{ documentId: string; loadId: string }> {
  return adminFetch(`/loads/${dto.loadId}/documents/${dto.documentId}`, {
    method: 'DELETE',
  })
}

export function useDeleteLoadDocument() {
  const queryClient = useQueryClient()

  return useMutation(deleteLoadDocument, {
    onSuccess: (res: { documentId: string; loadId: string }) => {
      queryClient.setQueriesData(['load', res.loadId], (prev?: any) => {
        return (
          prev && {
            ...prev,
            documents: (prev.documents ?? []).filter(
              (d: any) => d._id !== res.documentId,
            ),
          }
        )
      })

      toast.success('Document deleted successfully.')
    },
  })
}

export function useArchiveMissedPickup(loadId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    () => adminFetch(`/missed-pickups/${loadId}`, { method: 'DELETE' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['missed-pickup-list'])
        toast.success('Missed pickup archived successfully.')
      },
    },
  )
}

async function refetchExternalDocuments(dto: {
  loadId: string
}): Promise<{ loadId: string }> {
  return adminFetch(`/loads/${dto.loadId}/documents/external`)
}

export function useRefetchExternalDocuments(loadId: string) {
  const queryClient = useQueryClient()

  const mutation = useMutation(() => refetchExternalDocuments({ loadId }), {
    onSuccess() {
      toast.success('Document fetched successfully.')
      queryClient.invalidateQueries(['load', loadId])
      mutation.reset()
    },
    onError(e) {
      showApiError('Unable to sync documents', e)
    },
  })

  return mutation
}

async function addSpotQuoteRecipient(dto: {
  email: string
  loadId: string
}): Promise<AdminLoad> {
  return adminFetch(`/loads/${dto.loadId}/spot-quote-recipients`, {
    body: {
      email: dto.email,
    },
    method: 'POST',
  })
}

export function useAddSpotQuoteRecipient(loadId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    (email: string) => addSpotQuoteRecipient({ loadId, email }),
    {
      onError: (error: { message: string }) => {
        toast.error(error.message)
      },
      onSuccess: (res: AdminLoad) => {
        ;[['dispatch-list'], ['missing-bol-list']].forEach(key => {
          queryClient.setQueriesData(key, (prev: AdminLoad[] | undefined) => {
            return (prev ?? []).map((p: AdminLoad) => ({
              ...p,
              ...(p._id === res._id && res),
            }))
          })
        })

        queryClient.setQueriesData(
          ['load', res._id],
          (prev: AdminLoad | undefined) => {
            return { ...prev, ...res }
          },
        )

        toast.success('Spot quote recipient added successfully.')
      },
    },
  )
}

export function useRemoveSpotQuoteRecipient(loadId: string) {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userId }: { userId: string }) =>
      adminFetch<AdminLoad>(
        `/loads/${loadId}/spot-quote-recipients/${userId}`,
        {
          method: 'DELETE',
        },
      ),
    {
      onSettled(result, err) {
        if (err) {
          showApiError('could not remove spot quote recip', err)
          return
        }
        if (result) {
          queryClient.setQueriesData(
            ['load', loadId],
            (prev: AdminLoad | undefined) => {
              return { ...prev, ...result }
            },
          )
          toast.success('removed spot quote recipient')
        }
      },
    },
  )
}

import orderBy from 'lodash/orderBy'
import { useState } from 'react'

import { AdminSliderPanel } from '../../../components/AdminSliderPanel'
import { AdminLink } from '../../../components/shared/AdminLink'
import { AdminQueryTable } from '../../../components/shared/AdminTable'
import { useLoadTickets } from '../../../hooks/useLoadTickets'
import { friendlyDate } from '../../../utils'

type Props = {
  loadId: string
  btnClass?: string
}

export function TicketSlider(args: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const tickets = useLoadTickets(args.loadId, isOpen)

  return (
    <>
      <AdminLink
        icon="ticket"
        onClick={() => setIsOpen(true)}
        className={args.btnClass}
      >
        Zendesk
      </AdminLink>
      <AdminSliderPanel
        title="Zendesk Tickets"
        width="65rem"
        closePanel={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <AdminQueryTable
          query={tickets}
          emptyContent="No tickets found"
          rowKey={t => t.id.toString()}
          columns={[
            {
              key: '',
              label: '',
              render: d => (
                <AdminLink
                  icon="external-link-alt"
                  href={`https://freightview.zendesk.com/agent/tickets/${d.id}`}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            },
            {
              key: 'createdDate',
              label: 'Created',
              sort: (data, dir) => {
                return orderBy(data, d => d.created_at, dir)
              },
              render: d => friendlyDate(d.created_at, 'MM/DD/YY hh:mm A'),
            },
            { key: 'subject', label: 'Subject', render: d => d.subject },
            { key: 'status', label: 'Status', render: d => d.status },
          ]}
        />
      </AdminSliderPanel>
    </>
  )
}

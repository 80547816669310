import {
  vCountryAny,
  vEmailOrEmpty,
  vMinLength,
  vNonEmptyString,
  vNumber,
  vObject,
  vOptional,
  vOutput,
  vPhoneExt,
  vPhoneNumber,
  vPicklist,
  vPipe,
  vPostalCode,
  vString,
} from '../validation'
import { AddressBase } from './address'

export const customsBrokerTypes = ['import', 'export'] as const
export type CustomsBrokerType = (typeof customsBrokerTypes)[number]

export const baseCustomsBrokerSchema = vObject({
  address: vOptional(vString()),
  address2: vOptional(vString()),
  city: vOptional(vString()),
  companyName: vNonEmptyString(),
  country: vOptional(vCountryAny),
  email: vOptional(vEmailOrEmpty),
  phone: vPipe(vPhoneNumber(), vMinLength(1)),
  phoneExt: vOptional(vPhoneExt()),
  postalCode: vOptional(vPostalCode()),
  state: vOptional(vString()),
  type: vOptional(vPicklist(customsBrokerTypes)),
  value: vOptional(vNumber()),
})
export type ShipmentCustomsBrokerDto = vOutput<typeof baseCustomsBrokerSchema>
export interface LoadCustomsBroker extends Partial<AddressBase> {
  companyName: string
  email?: string
  phone: string
  phoneExt?: string
  value?: number
  type?: CustomsBrokerType
}

import { ObjectId } from '@freightview/object-id'
import { empty } from 'valibot'

import { billToSchema } from '@fv/models'

import {
  vAny,
  vArray,
  vBoolean,
  vCountryUpper,
  vDate,
  vDomain,
  vEmailOrEmpty,
  vFallback,
  vInput,
  vInt,
  vMaxLength,
  vMinLength,
  vMinValue,
  vNumber,
  vObject,
  vObjectId,
  vOmit,
  vOneOf,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vRawCheck,
  vString,
  vTransform,
} from '../validation'

export const checkDigitTypes = [
  'check-digit-always-0',
  'mod7',
  'mod7-SAIA',
  'mod10-ibm-aact',
  'mod10',
  'mod11',
  'edxi',
  'mod10-dlds',
] as const

export type ProNumberCheckDigitType = (typeof checkDigitTypes)[number]

export const carrierStatuses = [
  'active',
  'hidden',
  'removed',
  'pending',
] as const

export type CarrierStatus = (typeof carrierStatuses)[number]

const proNumberBlockStatus = ['active', 'empty', 'pending'] as const

export const dispatchModes = [
  'disabled', // Cannot dispatch
  'manual', // Shipper must dispatch
  'internal', // Fake dispatch via zendesk
  'email', // Email pickup request
  'auto', // Auto-dispatch via iris
] as const

const dispatchModesSchema = vPicklist(dispatchModes)

export type DispatchMode = vInput<typeof dispatchModesSchema>

export const dispatchFallbackModes = [
  'internal', // Fake dispatch via zendesk
  'email', // Email pickup request
] as const
const fallbackModesSchema = vPicklist(dispatchFallbackModes)

export type FallbackMode = vInput<typeof fallbackModesSchema>

export const truckloadDispatchModes = ['auto', 'email'] as const

export const carrierCancelModes = [
  'manual', // Shipper cancels their own pickup
  'email', // Automatic email is sent to the carrier
  'internal', // Our internal support team will handle the cancel
  'auto', // The carrier has an API that supports cancellations.
] as const

export type CancelMode = (typeof carrierCancelModes)[number]

export const proNumberBlockSchema = vObject({
  _id: vPipe(
    vObjectId(),
    vTransform(v => ObjectId.createFromHexString(v)),
  ),
  status: vPicklist(proNumberBlockStatus),
  start: vNumber(),
  end: vNumber(),
})
export type ProNumberBlock = vOutput<typeof proNumberBlockSchema>

export const carrierDispatchSettingsSchema = vObject({
  rerateBefore: vOptional(vBoolean()),
  mode: vFallback(dispatchModesSchema, 'internal'),
  fallbackMode: vFallback(fallbackModesSchema, 'internal'),
  betaMode: vFallback(dispatchModesSchema, 'internal'),
  requireCustomsBrokerValue: vOptional(vBoolean()),
  requirePickupNumberForInternal: vOptional(vBoolean()),
  phone: vOptional(vString()),
  email: vOptional(vEmailOrEmpty),
  supportsBolOnly: vOptional(vBoolean()), // whether or not IRIS supports BOL Only,
  pollIrisForBOL: vOptional(vBoolean()),
  bolTimeout: vOptional(vNumber()),
  hideFreightviewBOL: vOptional(vBoolean()),
  generateProNumber: vOptional(vBoolean()),
  proNumberEnableCheckDigit: vOptional(vBoolean()),
  proNumberCheckDigitType: vOptional(vPicklist(['', ...checkDigitTypes])),
  proNumberFixedWidthSize: vOptional(vNumber()),
  proNumberLowCountNotified: vOptional(vBoolean()),
  proNumberTrimCheckDigitForPrint: vOptional(vBoolean()),
  proNumberMaxLength: vOptional(vNumber()),
  proNumberHideBarCodeFromPrint: vOptional(vBoolean()),
  proNumberRemainingNotificationCount: vOptional(vNumber()), // number remaining will trigger notification to admins,
  proNumberBlocks: vOptional(vArray(proNumberBlockSchema)),
})

export type CarrierDispatchSettings = vOutput<
  typeof carrierDispatchSettingsSchema
>

export const carrierCancelSchema = vObject({
  mode: vPicklist(carrierCancelModes),
  phone: vOptional(vString()),
  email: vOptional(vEmailOrEmpty),
})

export const carrierHistorySchema = vObject({
  _id: vPipe(
    vObjectId(),
    vTransform(v => ObjectId.createFromHexString(v)),
  ),
  message: vString(),
  created: vDate(),
  userId: vObjectId(),
  author: vString(),
  ipAddress: vString(),
  type: vFallback(vString(), 'general'),
  subType: vOptional(vString()),
})

export type CarrierHistory = vOutput<typeof carrierHistorySchema>
export type CarrierHistoryDTO = vInput<typeof carrierHistorySchema>

export const carrierSchema = vObject({
  _id: vPipe(
    vObjectId(),
    vTransform(v => ObjectId.createFromHexString(v)),
  ),
  code: vOptional(
    vOneOf([
      vPipe(vString(), empty()),
      vPipe(vString(), vMinLength(4), vMaxLength(4)),
    ]),
  ),
  name: vString(),
  status: vPicklist(carrierStatuses),
  isVerified: vBoolean(),
  domains: vOptional(vArray(vDomain)),
  hideFreightviewBOLFromSpotQuote: vOptional(vBoolean()),
  createdDate: vOptional(vDate()),
  dispatch: vOptional(carrierDispatchSettingsSchema),
  dontSendBusIdInSpotQuotes: vOptional(vBoolean()),
  cancel: vOptional(carrierCancelSchema),

  parcel: vOptional(
    vObject({
      carrierNameOverride: vOptional(vString()),
      dispatch: vObject({
        betaMode: vFallback(dispatchModesSchema, 'internal'),
        fallbackMode: vFallback(fallbackModesSchema, 'internal'),
        mode: vFallback(dispatchModesSchema, 'internal'),
        phone: vOptional(vString()),
      }),
    }),
  ),

  provisionerId: vOptional(
    vPipe(
      vObjectId(),
      vTransform(i => ObjectId.createFromHexString(i)),
    ),
  ),

  requiresOriginEmail: vOptional(vBoolean()),

  ltl: vOptional(
    vObject({
      carrierNameOverride: vOptional(vString()),
      cache: vOptional(vAny()),
      labelsRequireBOL: vOptional(vBoolean()),
    }),
  ),

  truckload: vOptional(
    vObject({
      carrierNameOverride: vOptional(vString()),
      billToOverride: vOptional(
        vObject({
          ...billToSchema.entries,
          country: vFallback(vCountryUpper, 'US'),
        }),
      ),
      rating: vOptional(
        vObject({
          allowManualAPIQuoteEdit: vOptional(vBoolean()),
          requiresQuoteNumber: vOptional(vBoolean()),
          supportsAPIRating: vOptional(vBoolean()),
        }),
      ),
      tender: vOptional(
        vObject({
          betaMode: vOptional(vPicklist(truckloadDispatchModes)),
          bolTimeoutHours: vOptional(vNumber()),
          fallbackMode: vOptional(vPicklist(['internal'])),
          mode: vPicklist(['auto', 'email']),
          requiresDeclaredValue: vOptional(vBoolean()),
          requiresFullBOLDetails: vOptional(vBoolean()),
        }),
      ),
      cancel: vOptional(
        vObject({
          mode: vOptional(vPicklist(['auto', 'manual'])),
        }),
      ),
    }),
  ),

  autoTrack: vOptional(vBoolean()),

  supportsManualRates: vOptional(vBoolean()),
  supportsLTLRates: vOptional(vBoolean()),
  supportsParcelRates: vOptional(vBoolean()),
  supportsTruckLoadRates: vOptional(vBoolean()),
  allowCustomerRoutedShipment: vOptional(vBoolean()),
  requireCustomerRoutedAccountNumber: vOptional(vBoolean()),
  allowBolOnly: vOptional(vBoolean()),
  allowChangeBOL: vOptional(vBoolean()),
  allowChangePickup: vOptional(vBoolean()),
  userProvisioned: vOptional(vBoolean()),
  autoProvision: vOptional(vBoolean()),
  verifyAutoProvision: vOptional(vBoolean()),
  requireSalesRepEmail: vOptional(vBoolean()),

  provision: vOptional(
    vObject({
      beta: vOptional(vBoolean()),
      validateLoginCredentials: vOptional(vBoolean()),
    }),
  ),

  headquartersState: vOptional(vString()),
  headquartersCity: vOptional(vString()),
  headquartersPostalCode: vOptional(vString()),
  headquartersCountry: vOptional(vCountryUpper),

  viewOtherRates: vOptional(vBoolean()),

  // TODO provisioning - need to decide if we want to keep these bits
  // right now they're used to determine what fields to show shipper
  // they're also used to determine when we should log provisoning errors to zendesk
  requiresApiCredentials: vOptional(vBoolean()),
  requiresApiKey: vOptional(vBoolean()),
  requiresLocationApiKey: vOptional(vBoolean()),
  // End TODO

  carrierNotifiesBOL: vOptional(vBoolean()),
  delayedProvision: vOptional(vBoolean()),
  logAutoProvision: vOptional(vBoolean()),
  credentialsVisible: vOptional(vBoolean()),
  imageAlias: vOptional(vString()),
  mcnum: vOptional(vArray(vString())),
  termsAndConditionsUrl: vOptional(vString()),
  usdot: vOptional(vString()),
  website: vOptional(vString()),
  incognito: vOptional(vBoolean()),
  redirectToExternalBOL: vOptional(vBoolean()),
  showNMFCOnQuotePage: vOptional(vBoolean()),
  alphanum_name: vOptional(vArray(vString())),
  metaphone_name: vOptional(vArray(vString())),
  supportsSpotVolumeRates: vOptional(vBoolean()),
  requiresSpotVolumeKey: vOptional(vBoolean()),
  requiresLocationAddressId: vOptional(vBoolean()),
  defaultToCarrierLabel: vOptional(vBoolean()),
  requiresAccountNumber: vOptional(vBoolean()),
  requiresApiPassword: vOptional(vBoolean()),
  supportsDynamicRates: vOptional(vBoolean()),
  requiresMultiAPIKeys: vOptional(vBoolean()),
  requireEULA: vOptional(vBoolean()),
  spotQuoteEmails: vOptional(vArray(vString())),
  cache: vOptional(vAny()),

  scacs: vOptional(vArray(vString())),

  history: vOptional(vArray(carrierHistorySchema)),

  newCarrierId: vOptional(
    vPipe(
      vObjectId(),
      vTransform(v => ObjectId.createFromHexString(v)),
    ),
  ),

  invoice: vOptional(
    vObject({
      disableDocumentParsing: vOptional(vBoolean()),
      aiPrompt: vOptional(vString()),
    }),
  ),
})

export type CarrierDTO = vInput<typeof carrierSchema>
export type Carrier = vOutput<typeof carrierSchema>

export const carrierUpdateSchema = vOmit(carrierSchema, [
  'history',
  'createdDate',
  '_id',
])

export const addProBlockSchema = vPipe(
  vObject({
    start: vPipe(vNumber(), vInt(), vMinValue(0)),
    end: vPipe(vNumber(), vInt(), vMinValue(1)),
  }),
  vRawCheck(({ dataset, addIssue }) => {
    if (dataset.typed && dataset.value.end <= dataset.value.start) {
      addIssue({
        message: 'Block end must be greater than block start.',
      })
    }
  }),
)

export type AddProBlockDTO = vOutput<typeof addProBlockSchema>

export type ProvisioningStats = {
  activeCountTotal: number
  activeCountLTL: number
  activeCountParcel: number
  activeCountTruckload: number
  activeCountVolume: number
  pendingCountTotal: number
  pendingCountLTL: number
  pendingCountParcel: number
  pendingCountTruckload: number
  pendingCountVolume: number
}

export type CarrierStatsDTO = {
  quotes: number
  spotAccounts: number
  volume: {
    count: number
    fum: number
  }
  accountsProvisioned: ProvisioningStats
}

export const mergeCarrierSchema = vObject({
  targetId: vObjectId(),
})

export type MergeCarrierDTO = vOutput<typeof mergeCarrierSchema>

import {
  vMinLength,
  vNonEmptyString,
  vObject,
  vOptional,
  vOutput,
  vPhoneExt,
  vPhoneNumber,
  vPipe,
  vString,
} from '../validation'

export const emergencyContactSchema = vObject({
  name: vNonEmptyString(),
  phone: vPipe(vPhoneNumber(), vMinLength(1)),
  phoneExt: vOptional(vPhoneExt()),
  contractNumber: vOptional(vString()),
})

export type EmergencyContactDto = vOutput<typeof emergencyContactSchema>

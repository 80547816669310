import { useQuery } from '@tanstack/react-query'
import { isNil, omitBy } from 'lodash'
import toast from 'react-hot-toast'

import { type AdminLoadSearchDTO } from '@fv/models'

import { type AdminLoad } from '../types/admin-load'
import { adminFetch } from '../utils'

export const loadSearchQueryKeys = {
  all: ['loads'],
  search: (s: string) => [...loadSearchQueryKeys.all, s],
}

async function searchLoads(opts: AdminLoadSearchDTO) {
  return adminFetch<AdminLoad[]>('/loads/search', {
    query: omitBy(opts, o => o === '' || isNil(o)),
  })
}

export function useSearchLoads(opts: AdminLoadSearchDTO) {
  const qs = new URLSearchParams(opts as any).toString()

  return useQuery<AdminLoad[]>(
    loadSearchQueryKeys.search(qs),
    () => searchLoads(opts),
    {
      retry: false,
      staleTime: 300000, // 5 minutes
      onError: (error: any) => {
        toast.error(error.message)
      },
    },
  )
}

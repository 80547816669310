import { useEffect } from 'react'

import { siteTitle } from '../../constants'
import { Search } from '../carrier-users/Search'
import { NotificationsCard } from '../notifications/NotificationsCard'

export const AdminHomePage = () => {
  useEffect(() => {
    document.title = `Admin Portal - ${siteTitle}`
  }, [])

  return (
    <main role="main" className="container mx-auto py-4 flex flex-col gap-4">
      <NotificationsCard />
      <Search />
    </main>
  )
}

import { type ComponentProps } from 'react'

type Props = ComponentProps<'input'> & {
  label: string
}
export const AdminRadio = ({ className, label, ...props }: Props) => (
  <div className="form-control">
    <label className="label cursor-pointer">
      <span className="label-text">{label}</span>
      <input type="radio" className="radio checked:bg-red-500" {...props} />
    </label>
  </div>
)

import { readonly } from 'valibot'

import { DTO } from '../core'
import {
  vArray,
  vBoolean,
  vCountryAny,
  vDate,
  vMinValue,
  vNumber,
  vObject,
  vObjectId,
  vOmit,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vPostalCode,
  vString,
  vStringToNumber,
} from '../validation'
import { equipmentTypes } from './equipment-type'
import { sortDirSchema } from './sort'
import { states } from './state'

export const contractedRatesFilterSchema = vObject({
  carrierId: vOptional(vString()),
  originSearch: vOptional(vString()),
  destSearch: vOptional(vString()),
  contractSearch: vOptional(vString()),
  showExpired: vOptional(vBoolean()),
  limit: vOptional(vStringToNumber()),
  continuationToken: vOptional(vString()),
  sortBy: vOptional(
    vPicklist([
      'createdAt',
      'startDate',
      'endDate',
      'contractNumber',
      'carrierName',
      'origin',
      'destination',
      'amount',
      'status',
    ]),
  ),
  sortDir: vOptional(sortDirSchema),
})

export type ContractedRatesFilter = vOutput<typeof contractedRatesFilterSchema>

export const extendsRatesSchema = vObject({
  endDate: vDate(),
  rates: vArray(vObjectId()),
})

export type ExtendRatesDTO = vOutput<typeof extendsRatesSchema>

const locationSchema = vObject({
  country: vCountryAny,
  postalCode: vOptional(vPostalCode(true)),
  postalCodeEnd: vOptional(vPostalCode(true)),
  state: vOptional(vPicklist(states.map(s => s.abbreviation))),
  meta: vOptional(
    vPipe(
      vObject({
        city: vOptional(vString()),
        state: vOptional(vPicklist(states.map(s => s.abbreviation))),
      }),
      readonly(),
    ),
  ),
})

export const contractedRateSchema = vObject({
  _id: vObjectId(),
  carrierName: vOptional(vString()),
  accountId: vString(),
  addedById: vString(),
  carrierId: vString(),
  createdDate: vDate(),
  contractNumber: vOptional(vString()),
  destination: locationSchema,
  distance: vOptional(vPipe(vNumber(), vMinValue(1))),
  distanceUOM: vPicklist(['mi']),
  endDate: vDate(),
  equipmentTypes: vArray(vPicklist(equipmentTypes.map(e => e.key))),
  fuelIncluded: vBoolean(),
  origin: locationSchema,
  rate: vObject({
    amount: vNumber(),
    currency: vPicklist(['usd']),
    rateType: vPicklist(['flat', 'per-mile']),
    serviceId: vPicklist(['truckload']),
  }),
  startDate: vDate(),
  minimumRate: vOptional(vNumber()),
  continuationToken: vOptional(vString()),
  errors: vOptional(
    vArray(
      vObject({
        message: vString(),
        path: vString(),
      }),
    ),
  ),
  isRemoved: vOptional(vBoolean()),
})

export type ContractedRateBase = vOutput<typeof contractedRateSchema>
export type ContractedRateLocation = vOutput<typeof locationSchema>

export type ContractedRate = Partial<DTO<ContractedRateBase>>

export const editContractedRateSchema = vOmit(contractedRateSchema, [
  '_id',
  'carrierName',
  'accountId',
  'addedById',
  'createdDate',
  'continuationToken',
  'errors',
  'isRemoved',
])

export const addRatesSchema = vObject({
  rates: vArray(editContractedRateSchema),
})

export type AddContractedRatesDTO = vOutput<typeof addRatesSchema>

export const displayContractedRateLoc = (loc: ContractedRateLocation) => {
  if (loc.state) {
    return loc.state
  }

  if (loc.postalCode && loc.postalCodeEnd) {
    return `${loc.postalCode}-${loc.postalCodeEnd}`
  }

  if (loc.meta?.city && loc.meta?.state) {
    return `${loc.meta?.city}, ${loc.meta?.state} ${loc.postalCode}`
  }

  return loc.postalCode
}

import { QuoteCarrier } from '@fv/client-components'
import { type AdminLoad } from '@/types/admin-load'

import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminSection } from '../../../components/shared/AdminSection'
import { AdminTable } from '../../../components/shared/AdminTable'
import { friendlyDate } from '../../../utils'
import { useForceRerate } from '../../quotes/mutations'
import { SerializeSlider } from './SerializeSlider'
import { type LoadPanelProps } from './types'

export function QuotesPanel({ load }: LoadPanelProps) {
  return (
    <AdminSection title="Quotes" headerActions={<QuoteActions load={load} />}>
      <AdminTable
        data={load.quotes}
        rowKey={q => q._id}
        columns={[
          {
            key: 'createdDate',
            label: 'Created Date',
            render: q => friendlyDate(q.createdDate),
          },
          {
            key: 'assetCarrierName',
            label: 'Carrier',
            render: q => <QuoteCarrier quote={q} />,
          },
          { key: 'quoteNum', label: 'Quote Number', render: d => d.quoteNum },
          { key: 'status', label: 'Status', render: d => d.status },
          { key: 'source', label: 'Source', render: d => d.source },
          {
            key: 'method',
            label: 'Method',
            render: d => `${d.method} ${d.scrape ? '(scrape)' : ''}`,
          },
          { key: 'amount', label: 'Total', render: d => d.amount },
        ]}
      />
    </AdminSection>
  )
}

const QuoteActions = ({ load }: { load: AdminLoad }) => {
  const forceRerate = useForceRerate(load._id)
  const canForceRerate = load.status === 'quoting'

  return (
    <>
      {canForceRerate && (
        <AdminButton
          onClick={() => forceRerate.mutate()}
          icon="redo"
          className="btn-sm"
          loading={forceRerate.isLoading}
        >
          Force Rerate
        </AdminButton>
      )}

      <SerializeSlider loadId={load._id} output="rating" />
    </>
  )
}

import {
  vArray,
  vBoolean,
  vCoerceArray,
  vCountryAny,
  vMaxLength,
  vMinLength,
  vObject,
  vOmit,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vString,
  vTransform,
} from '../validation'
import { billToSchema } from './account-bill-to'
import { workflows } from './common'
import { baseCustomsBrokerSchema } from './customs-broker'
import { deliveryOptionSchema } from './delivery-option'
import { emergencyContactSchema } from './emergency-contact'
import { loadEquipmentSchema } from './load-equipment'
import { loadHandlingUnitSchema } from './load-handling-unit'
import { loadLocationSchema } from './load-location'
import { loadRefNumSchema } from './ref-num'

export const baseLoadBillToSchema = vObject({
  ...vOmit(billToSchema, ['country']).entries,
  country: vCountryAny,
})
export const upsertLoadSchema = vObject({
  billTo: vOptional(baseLoadBillToSchema),
  refNums: vPipe(
    vOptional(vArray(loadRefNumSchema)),
    vTransform(value => value ?? []),
  ),
  workflow: vPicklist(workflows),
  bolNumber: vOptional(vString()),
  emergencyContact: vOptional(emergencyContactSchema),
  customsBrokers: vOptional(vCoerceArray(baseCustomsBrokerSchema)),
  locations: vPipe(vArray(loadLocationSchema), vMinLength(2), vMaxLength(2)),
  items: vPipe(vArray(loadHandlingUnitSchema), vMinLength(1)),
  equipment: loadEquipmentSchema,
  rateLocationType: vOptional(vPicklist(['origin', 'destination'])),
  isLiveLoad: vBoolean(),
  schedulePickup: vOptional(vBoolean()),
  deliveryOption: vOptional(deliveryOptionSchema),
  isFreightCollect: vOptional(vBoolean()),
  quoteRequestId: vString(),
})
export type UpsertLoadDTO = vOutput<typeof upsertLoadSchema>
export const loadStatuses = [
  'quoting',
  'booked',
  'canceled',
  'discarded',
] as const
export type LoadStatus = (typeof loadStatuses)[number]

import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminFormGroup } from '../../../components/shared/AdminFormGroup'
import { AdminSection } from '../../../components/shared/AdminSection'
import { AdminTable } from '../../../components/shared/AdminTable'
import { useUncancelLoad } from '../../../hooks/useUncancelLoad'
import { friendlyDate } from '../../../utils'
import { type LoadPanelProps } from './types'

export const CancelPanel = ({ load }: LoadPanelProps) => {
  const uncancelLoad = useUncancelLoad()

  if (!load.cancel?.status || load.cancel.status === 'not-requested') {
    return null
  }

  const { cancel } = load
  const canUncancel =
    (load.status === 'canceled' || load.status === 'discarded') &&
    !load.isLegacy

  return (
    <AdminSection
      title="Cancel details"
      headerActions={
        !canUncancel ? null : (
          <AdminButton
            icon="redo"
            loading={uncancelLoad.isLoading}
            onClick={() => uncancelLoad.mutate({ loadId: load._id })}
            className="btn-sm"
          >
            Uncancel
          </AdminButton>
        )
      }
    >
      <div className="grid lg:grid-cols-2 xl:grid-cols-4">
        <AdminFormGroup label="status">{cancel.status}</AdminFormGroup>
        <AdminFormGroup label="date">
          {friendlyDate(cancel.date)}
        </AdminFormGroup>
        <AdminFormGroup label="method">{cancel.mode}</AdminFormGroup>
        {cancel.mode === 'email' && (
          <>
            <AdminFormGroup label="email">{cancel.email?.to}</AdminFormGroup>
            <AdminFormGroup label="email status">
              {cancel.email?.status}
            </AdminFormGroup>
          </>
        )}
        <AdminFormGroup label="canceled by">
          {cancel.canceledByUser || cancel.canceledByUserId}
        </AdminFormGroup>
      </div>
      {cancel.errors?.length && (
        <>
          <div className="my-2">Errors</div>
          <AdminTable
            data={cancel.errors}
            rowKey={d => d.message}
            columns={[
              {
                key: 'type',
                render: data => data.type,
              },
              {
                key: 'message',
                render: data => data.message,
              },
            ]}
          />
        </>
      )}
    </AdminSection>
  )
}

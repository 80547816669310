import {
  vArray,
  vBoolean,
  vCurrency,
  vInt,
  vMinValue,
  vNonEmptyString,
  vNumber,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vRegex,
  vString,
  vStringToNumber,
  vTransform,
  vWeightUOM,
} from '../validation'
import {
  dimensionsUOM,
  freightClasses,
  hazardClasses,
  hazardSubClasses,
  itemRefNumTypes,
} from './common'
import { packagingTypes } from './packaging-type'
import { packingGroups } from './packing-group'

export const loadItemRefNumSchema = vObject({
  type: vPicklist(itemRefNumTypes),
  value: vString(),
  unitIndex: vOptional(vNumber()),
})

const subClass = vPipe(
  vOptional(vPicklist(hazardSubClasses)),
  vTransform(value => (!value ? undefined : value)),
)

export const baseLoadItemHazDetailSchema = vObject({
  hazmatId: vPipe(
    vNonEmptyString(),
    vRegex(/^(NA|UN)\d{4}$/),
    vTransform(v => (v ? v.toString().toUpperCase() : v)),
  ),
  permitNumber: vOptional(vString()),
  packingGroup: vPipe(
    vOptional(vPicklist(packingGroups.map(p => p.name))),
    vTransform(value => packingGroups.find(p => p.name === value)?.key),
  ),
  primaryClass: vPicklist(hazardClasses.map(h => h.key)),
  secondaryClass: subClass,
  tertiaryClass: subClass,
})

const vAtLeastOne = () => vPipe(vStringToNumber(), vMinValue(1))
const vAtLeastOneInt = () => vPipe(vStringToNumber(), vInt(), vMinValue(1))

const weight = vAtLeastOne()
const quantity = vAtLeastOneInt()
const weightUOM = vWeightUOM
const description = vString()

const baseLoadItemSchema = vObject({
  _id: vString(),
  customsValue: vOptional(vAtLeastOne()),
  customsValueCurrency: vOptional(vCurrency('usd')),
  declaredValue: vOptional(vAtLeastOne()),
  declaredValueCurrency: vOptional(vCurrency('usd')),
  dimensionsUOM: vOptional(vPicklist(dimensionsUOM)),
  freightClass: vOptional(vPicklist(freightClasses)),
  height: vOptional(vAtLeastOne()),
  length: vOptional(vAtLeastOne()),
  nmfcNumber: vOptional(vStringToNumber()),
  nmfcSubNumber: vOptional(vStringToNumber()),
  quantity: vOptional(quantity),
  refNums: vOptional(
    vArray(
      vObject({
        type: vPicklist(itemRefNumTypes),
        value: vString(),
        unitIndex: vOptional(vPipe(vStringToNumber(), vInt())),
      }),
    ),
  ),
  width: vOptional(vAtLeastOne()),
  weight: vOptional(weight),
  weightUOM: vOptional(weightUOM),
  description: vOptional(description),
  type: vOptional(
    vPicklist(packagingTypes.filter(p => !!p.canBeContained).map(p => p.key)),
  ),
  hazardous: vOptional(vArray(baseLoadItemHazDetailSchema)),
})

export const loadHandlingUnitSchema = vObject({
  ...baseLoadItemSchema.entries,
  weight,
  weightUOM,
  description,
  quantity,
  type: vPicklist(packagingTypes.map(p => p.key)),
  pickupLocationSequence: vPipe(vStringToNumber(), vInt(), vMinValue(0)),
  dropLocationSequence: vAtLeastOneInt(),
  stackable: vOptional(vBoolean()),
  nonStandard: vOptional(vBoolean()),
  contains: vOptional(vArray(baseLoadItemSchema)),
})
export type LoadHandlingUnitDTO = vOutput<typeof loadHandlingUnitSchema>

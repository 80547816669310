import { sum } from 'lodash'

import { Quote, SelectFieldOption } from '@fv/client-types'
import {
  ChargeName,
  charges,
  UpdateQuoteDTO,
  UpsertChargeDTO,
} from '@fv/models'
import { capitalizeFirstLetter } from '@fv/models/core'

import { generateString } from './random'

export interface ChargeModel extends UpsertChargeDTO {
  id: string
}

type Model = Pick<Quote, 'amount' | 'currency'> & {
  charges?: ChargeModel[]
  quoteId?: string
}

export const emptyCharge: ChargeModel = {
  id: '',
  name: '' as ChargeName,
  amount: 0,
  currency: 'usd',
}

export const generateCharge = (base?: Partial<ChargeModel>): ChargeModel => ({
  ...emptyCharge,
  ...base,
  id: generateString(10),
})

export const switchFromCharges = <T extends Model>(
  model: T,
): T & { amount: number; charges: ChargeModel[] } => ({
  ...model,
  amount: sum(model.charges?.map(c => c.amount)),
  charges: undefined,
})

export const switchToCharges = <T extends Model>(
  model: T,
): T & { charges: ChargeModel[] } => ({
  ...model,
  charges: [
    generateCharge({ name: 'linehaul', amount: model.amount }),
    generateCharge(),
  ],
})

export const getAmount = (model: Model) => {
  return model.charges?.length
    ? sum(model.charges.map(c => c.amount))
    : model.amount
}

export const modelToDto = <T extends Model>(model: T): T & UpdateQuoteDTO => ({
  ...model,
  amount: getAmount(model),
  charges: model.charges?.map(({ id, ...charge }) => charge),
  quoteId: model.quoteId || undefined,
})

export const chargeOptions: SelectFieldOption[] = [
  { text: 'Select charge', value: '' },
  ...charges
    .filter(c => c.name !== 'linehaul') // we're considering "all-in" rate the linehaul rate
    .map(c => ({
      text: capitalizeFirstLetter(c.name),
      value: c.name,
    })),
]

import { useQuery } from '@tanstack/react-query'

import { type AccountVendor, type AccountVendorUser } from '@fv/models'
import { type AdminVendorOverview } from '@fv/models/admin'
import { type DTO } from '@fv/models/core'
import { adminFetch } from '@/utils'

export const vendorQueryKeys = {
  all: ['vendors'],
  account: (id: string) => [...vendorQueryKeys.all, 'account', id],
  overview: (id: string) => [...vendorQueryKeys.all, id, 'overview'],
  users: (id: string) => [...vendorQueryKeys.all, id, 'users'],
}

export const useVendorOverview = (vendorId: string) => {
  return useQuery(
    vendorQueryKeys.overview(vendorId),
    () => adminFetch<DTO<AdminVendorOverview>>(`/vendors/${vendorId}`),
    {
      staleTime: Infinity,
      enabled: !!vendorId,
    },
  )
}

export const useVendorUsers = (id: string) =>
  useQuery(vendorQueryKeys.users(id), () =>
    adminFetch<AccountVendorUser[]>(`/vendors/${id}/users`),
  )

export const useListAccountVendors = (accountId: string) => {
  return useQuery(vendorQueryKeys.account(accountId), () =>
    adminFetch<Array<DTO<AccountVendor>>>(`/accounts/${accountId}/vendors`),
  )
}

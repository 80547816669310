import { useState } from 'react'

import { AdminSelect } from '@/components/shared/AdminSelect'

import { AdminSliderPanel } from '../../../components/AdminSliderPanel'
import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminCode } from '../../../components/shared/AdminCode'
import {
  type SerializeLoadArgs,
  useSerializeLoad,
} from '../../../hooks/useSerializeLoad'

export function SerializeSlider(args: SerializeLoadArgs) {
  const [isOpen, setIsOpen] = useState(false)
  const serializeLoad = useSerializeLoad(args, isOpen)
  const [currentCode, setCurrentCode] = useState('all')
  const data: object | undefined = serializeLoad.data

  let value
  const options: string[] = []
  if (data) {
    value = JSON.parse(JSON.stringify(data))
    value?.carrierCredentials?.forEach((element: any) =>
      options.push(element.providerCode),
    )
    value?.credentials?.forEach((element: any) => options.push(element.code))
    options.sort()
  }
  options.unshift('all')

  return (
    <>
      <AdminButton
        icon="code"
        onClick={() => setIsOpen(true)}
        className="btn-sm !ml-auto"
      />
      <AdminSliderPanel
        title={`Serialized for ${args.output}`}
        closePanel={() => setIsOpen(false)}
        isOpen={isOpen}
        width="60rem"
      >
        <AdminSelect
          name="theme"
          className="select-sm"
          options={options}
          value={currentCode}
          onChange={e => setCurrentCode(e.target.value)}
        />

        {serializeLoad.isLoading && <div>Loading...</div>}
        {!!serializeLoad.data && (
          <AdminCode data={buildList(data, currentCode)} />
        )}
      </AdminSliderPanel>
    </>
  )
}

function buildList(data: any, carrier: string) {
  if (data) {
    const value = JSON.parse(JSON.stringify(data))
    if (carrier !== 'all') {
      if (value?.carrierCredentials) {
        value.carrierCredentials = value.carrierCredentials.filter(
          (c: { code: any }) => c.code === carrier,
        )
      } else if (value?.credentials) {
        value.credentials = value.credentials.filter(
          (c: { code: any }) => c.code === carrier,
        )
      }
    }
    return value
  }
  return data
}

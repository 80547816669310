import { ObjectId } from '@freightview/object-id'

import {
  vArray,
  vBoolean,
  vEmail,
  vNonEmptyString,
  vNumber,
  vObject,
  vOneOf,
  vOptional,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import { UserPermissionType, userPermissionTypes } from './common'

const accountUserProfileBase = vObject({
  email: vEmail,
  firstName: vNonEmptyString(),
  lastName: vNonEmptyString(),
  phoneNumber: vString(),
  title: vString(),
  tac: vOptional(vBoolean()),
})

const onboardingProfileSchema = vObject({
  ...accountUserProfileBase.entries,
  company: vString(),
})
const userSetupProfileSchema = vObject({
  ...accountUserProfileBase.entries,
  password: vString(),
})

export const accountUserProfileUpdateSchema = vOneOf([
  // order of these is important
  userSetupProfileSchema,
  onboardingProfileSchema,
  accountUserProfileBase,
])

export type OnboardingProfileDto = vOutput<typeof onboardingProfileSchema>
type UserSetupProfileDto = vOutput<typeof userSetupProfileSchema>
export type AccountUserProfileUpdateDto = vOutput<
  typeof accountUserProfileUpdateSchema
>

export const isOnboardingProfileDto = (
  v: AccountUserProfileUpdateDto,
): v is OnboardingProfileDto => !!(v as OnboardingProfileDto).company

export const isUserSetupProfileDto = (
  v: AccountUserProfileUpdateDto,
): v is UserSetupProfileDto => !!(v as UserSetupProfileDto).password

export interface AccountUserListingResponse {
  _id: ObjectId
  username: string
  firstName: string
  lastName: string
  phone?: string
  title?: string
  permissions: UserPermissionType[]
  owner: boolean
  rate_modifier?: number
  removed?: boolean
  registered?: Date
}

export const accountUserMutationSchema = vObject({
  markup: vOptional(vNumber()),
  permissions: vArray(vPicklist(userPermissionTypes)),
})
export type AccountUserMutationDto = vOutput<typeof accountUserMutationSchema>

export const accountUserCreateSchema = vObject({
  email: vEmail,
  firstName: vOptional(vString()),
  lastName: vOptional(vString()),
  ...accountUserMutationSchema.entries,
})
export type AccountUserCreateDto = vOutput<typeof accountUserCreateSchema>

type AdminAccountPage =
  | 'overview'
  | 'users'
  | 'products'
  | 'carriers'
  | 'locations'
  | 'addresses'
  | 'history'
  | 'notes'
  | 'settings'
export const adminRoutes = {
  accountPage: (accountId: string, page: AdminAccountPage = 'overview') =>
    `/accounts/${accountId}/${page}`,
  vendorPage: (vendorId: string) => `/vendors/${vendorId}`,
  carrierPage: (carrierId: string) => `/carriers/${carrierId}/overview`,
}

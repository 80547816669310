import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { FvButton, useFvNavigate } from '@fv/client-components'

import { AdminSection } from '../../../components/shared/AdminSection'
import { loadSearchQueryKeys } from '../../../hooks/useSearchLoads'
import { adminFetch } from '../../../utils'
import { type LoadPanelProps } from './types'

const permanentDelete = ({ loadId }: { loadId: string }) =>
  adminFetch(`/loads/${loadId}/permanent`, {
    method: 'DELETE',
  })
function useDeleteLoad() {
  const queryClient = useQueryClient()

  return useMutation(permanentDelete, {
    onSuccess: () => {
      queryClient.invalidateQueries(loadSearchQueryKeys.all)
      toast.success('Document deleted successfully.')
    },
  })
}
const doubleCheck = () =>
  window.confirm(
    `I'm not kidding.  We can't un-delete this.  Are you really REALLY sure you want to delete this load?`,
  )
const doConfirm = () =>
  window.confirm(
    'Are you sure you want to delete this load?  This action is not reversible.',
  )
type Props = LoadPanelProps
export const DangerPanel = ({ load }: Props) => {
  const deleteLoad = useDeleteLoad()
  const navigate = useFvNavigate()
  const handleClick = async () => {
    if (doConfirm() && doubleCheck()) {
      try {
        await deleteLoad.mutateAsync({ loadId: load._id })
        navigate('/loads')
      } catch {
        toast.error('Failed to delete load')
      }
    }
  }
  return (
    <AdminSection
      headerClassName="bg-error/40 text-error-content"
      title="Danger!"
    >
      <FvButton
        className="btn-sm btn-error"
        icon="exclamation-triangle"
        onClick={handleClick}
      >
        Delete this load permanently
      </FvButton>
    </AdminSection>
  )
}

import { type PropsWithChildren } from 'react'

export function KVDisplay({
  label,
  children,
}: PropsWithChildren<{ label: string }>) {
  return (
    <>
      {/* <dl className="mb-1 flex"> */}
      <dt className="pr-3 text-right font-bold">{label}:</dt>
      <dd>{children}</dd>
      {/* </dl> */}
    </>
  )
}

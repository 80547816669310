import { useQuery } from '@tanstack/react-query'

import { adminFetch } from '../utils'

export type Trace = {
  traceId: string
  timeStamp: string
  carrier?: {
    code: string
    id?: string
  }

  category: 'fv-api'
  action: string
  clientId?: string
  targetId?: string
  transactions: Transaction[]
}

export type FullTrace = Omit<Trace, 'transactions'> & {
  transactions: FullTransaction[]
}

export type Transaction = {
  id: string
  parentId?: string
  timeStamp: string
  layer: string
  processType: string
  error?: string
  errorStack?: string
}

type FullTransaction = Transaction & {
  request: {
    headers: any
    body?: any
    query?: any
  }

  response: {
    headers: any
    body?: any
  }
}

export function useFetchTraces(id?: string) {
  return useQuery<Trace[]>(
    ['traces', id],
    () => adminFetch(`/loads/${id}/traces`),
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
    },
  )
}

export function useFetchTrace(loadId: string, id: string, enabled: boolean) {
  return useQuery<FullTrace>(
    ['traces', id],
    () => adminFetch(`/loads/${loadId}/traces/${id}`),
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  )
}

import clsx from 'clsx'

import { type PropsWithClassName } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { type AdminLoad } from '../../types/admin-load'

export const LoadWorkflowBadge = ({
  className,
  workflow,
}: PropsWithClassName<{ workflow: Workflow }>) => (
  <span
    className={clsx('badge text-white', className, {
      'bg-ltl': workflow === 'ltl',
      'bg-truckload': workflow === 'truckload',
      'bg-parcel': workflow === 'parcel',
    })}
  >
    {workflow}
  </span>
)

type StatusProps = PropsWithClassName<Pick<AdminLoad, 'status'>>
export const LoadStatusBadge = ({ status, className }: StatusProps) => (
  <span
    className={clsx('badge text-white', className, {
      'bg-green-800': status === 'booked',
      'bg-teal-800': status === 'quoting',
      'bg-red-400': status === 'canceled' || status === 'discarded',
    })}
  >
    {status}
  </span>
)

type GenericBadgeType =
  | 'freight-collect'
  | 'v1-api'
  | 'v2-api'
  | 'legacy'
  | 'vendor'
type GenericBadgeProps = PropsWithClassName<{ type: GenericBadgeType }>
export const GenericBadge = ({ className, type }: GenericBadgeProps) => (
  <span
    className={clsx('badge text-white', className, {
      'bg-neutral-700': type === 'freight-collect',
      'bg-orange-950': type === 'v1-api',
      'bg-lime-600': type === 'v2-api',
      'bg-error': type === 'legacy',
      'bg-violet-600': type === 'vendor',
    })}
  >
    {type}
  </span>
)

type BadgeListProps = PropsWithClassName<{ load: AdminLoad }>

export const LoadBadgeList = ({ load, className }: BadgeListProps) => {
  return (
    <>
      <LoadWorkflowBadge className={className} workflow={load.workflow} />
      <LoadStatusBadge className={className} status={load.status} />
      {load.isFreightCollect && (
        <GenericBadge className={className} type="freight-collect" />
      )}
      {load.source === 'v1' && (
        <GenericBadge className={className} type="v1-api" />
      )}
      {load.source === 'v2' && (
        <GenericBadge className={className} type="v2-api" />
      )}
      {load.createdBy?.vendorId && (
        <GenericBadge className={className} type="vendor" />
      )}

      {load.isLegacy && <GenericBadge className={className} type="legacy" />}
    </>
  )
}

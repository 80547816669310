import isEqual from 'lodash/isEqual'
import reject from 'lodash/reject'

export const toggleArrayItem = <T>(
  arr: T[],
  item: T,
  toggle: boolean,
  pred: (item: T) => boolean = v => isEqual(v, item),
) => {
  const newArr = reject<T>(arr, pred)
  if (toggle) {
    return [...newArr, item]
  }
  return newArr
}

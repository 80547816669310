import { useState } from 'react'

import { FvButton, TextAreaField, ValidatedForm } from '@fv/client-components'
import { type AdminLoad } from '@/types/admin-load'

import { AdminFormGroup } from '../../components/shared/AdminFormGroup'
import { useAddIrisDispatch } from './mutations'

type Props = {
  className?: string
  load: AdminLoad
  onSubmit?: () => void
}
export function AddIrisDispatchForm({ className, load, onSubmit }: Props) {
  const addIrisDispatch = useAddIrisDispatch()
  const [payload, setPayload] = useState('')
  const [validationError, setError] = useState('')

  const onValidSubmit = () => {
    if (addIrisDispatch.isLoading) return

    try {
      JSON.parse(payload)
    } catch (e) {
      setError('Invalid JSON')
      return
    }

    addIrisDispatch
      .mutateAsync({
        irisPayload: payload,
        loadId: load._id,
      })
      .then(() => {
        onSubmit?.()
        setPayload('')
      })
  }

  return (
    <ValidatedForm className={className} onValidSubmit={onValidSubmit}>
      <AdminFormGroup className="my-3" label="Iris Response Payload">
        <TextAreaField
          name="payload"
          required
          error={validationError}
          className="h-[400px] border-2 border-slate-200 p-2"
          onChange={e => setPayload(e.target.value)}
        />
      </AdminFormGroup>

      <FvButton type="submit" icon="check" loading={addIrisDispatch.isLoading}>
        Apply Iris Dispatch
      </FvButton>
    </ValidatedForm>
  )
}

import { useState } from 'react'

import { ValidatedForm } from '@fv/client-components'

import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminFormGroup } from '../../../components/shared/AdminFormGroup'
import { AdminTextField } from '../../../components/shared/AdminTextField'
import { useUpdateTrackingNumber } from '../../dispatches/mutations'
import { type LoadPanelProps } from './types'

export const ProForm = ({ load, onChange }: LoadPanelProps) => {
  const updateTracking = useUpdateTrackingNumber(load._id)
  const [value, setValue] = useState(load.track?.trackingNumber ?? '')

  const handleSubmit = async () => {
    if (updateTracking.isLoading) return
    await updateTracking.mutateAsync(value)
    onChange?.()
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <AdminFormGroup className="my-3" label="Pro Number">
        <AdminTextField
          name="proNumber"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </AdminFormGroup>
      <AdminButton
        type="submit"
        className="btn-primary"
        loading={updateTracking.isLoading}
        icon={'check'}
      >
        Update Pro
      </AdminButton>
    </ValidatedForm>
  )
}

import { useState } from 'react'
import toast from 'react-hot-toast'

import { ValidatedForm } from '@fv/client-components'
import { type AdminLoad } from '@/types/admin-load'

import { AdminButton } from '../../components/shared/AdminButton'
import { AdminFormGroup } from '../../components/shared/AdminFormGroup'
import { AdminTextField } from '../../components/shared/AdminTextField'
import { useConfirmPickup } from './mutations'

type Props = {
  className?: string
  load: AdminLoad
  onSubmit?: () => void
}
export function ConfirmPickupForm({ className, load, onSubmit }: Props) {
  const confirmPickup = useConfirmPickup(load._id)
  const [confirmationNumber, setConfirmationNumber] = useState(
    load.pickup?.confirmationNumber ?? '',
  )

  const onValidSubmit = () => {
    if (confirmPickup.isLoading) return
    if (!confirmationNumber.trim()) {
      toast.error('Confirmation number is required.')
      return
    }

    confirmPickup.mutateAsync(confirmationNumber).then(() => {
      onSubmit?.()
      setConfirmationNumber('')
    })
  }

  const icon = confirmPickup.isLoading ? 'spinner' : 'check'

  return (
    <ValidatedForm className={className} onValidSubmit={onValidSubmit}>
      <AdminFormGroup className="my-3" label="Confirmation #">
        <AdminTextField
          disabled={confirmPickup.isLoading}
          name="pickupConfirmationNumber"
          onChange={e => setConfirmationNumber(e.target.value)}
          value={confirmationNumber}
        />
      </AdminFormGroup>

      <AdminButton className="btn-primary" type="submit" icon={icon}>
        Mark as dispatched
      </AdminButton>
    </ValidatedForm>
  )
}

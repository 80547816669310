import { FvDate } from '@fv/client-components'

import { AdminButton } from '../../components/shared/AdminButton'
import { AdminFormGroup } from '../../components/shared/AdminFormGroup'
import { useFetchGenericUser } from '../../hooks/useFetchUsers'
import { type AdminLoad } from '../../types/admin-load'
import { useArchiveMissedPickup } from './mutations'

type Props = {
  load: AdminLoad
  onSuccess?: () => void
}

export const ArchiveMissedPickupForm = ({ load, onSuccess }: Props) => {
  const archive = useArchiveMissedPickup(load._id)
  const user = useFetchGenericUser(load.missedPickup?.reportedById)
  const archivedBy = useFetchGenericUser(load.missedPickup?.archivedById)
  const { missedPickup } = load

  if (!missedPickup) {
    return null
  }

  return (
    <div className="flex flex-col">
      <h2>Missed pickup details</h2>
      <div className="grid grid-cols-2">
        <AdminFormGroup label="Reported by" className="text-sm">
          {user.data?.email}
        </AdminFormGroup>

        <AdminFormGroup label="Reported date" className="text-sm">
          <FvDate val={missedPickup.dateReported} />
        </AdminFormGroup>

        {!!missedPickup.isArchived && (
          <>
            <AdminFormGroup label="Archived by" className="text-sm">
              {archivedBy.data?.email || missedPickup.archivedById}
            </AdminFormGroup>

            <AdminFormGroup label="Archived date" className="text-sm">
              <FvDate val={missedPickup.archivedDate} />
            </AdminFormGroup>
          </>
        )}
        <AdminFormGroup label="Reason" className="col-span-2 text-sm">
          {missedPickup.reason}
        </AdminFormGroup>

        <AdminFormGroup label="Carrier Response" className="col-span-2 text-sm">
          {missedPickup.carrierResponse}
        </AdminFormGroup>

        <AdminFormGroup label="Did Reschedule" className="col-span-2 text-sm">
          {missedPickup.isRescheduled ? 'Yes' : 'No'}
        </AdminFormGroup>

        <AdminFormGroup
          label="Additional Details"
          className="col-span-2 text-sm"
        >
          {missedPickup.additionalDetails}
        </AdminFormGroup>
      </div>

      {!missedPickup.isArchived && (
        <AdminButton
          icon="check"
          className="btn-primary mt-2 w-1/2 min-w-fit"
          onClick={() => archive.mutateAsync().then(() => onSuccess?.())}
          loading={archive.isLoading}
        >
          Archive Missed Pickup
        </AdminButton>
      )}
    </div>
  )
}

import clsx from 'clsx'
import uniqueId from 'lodash/uniqueId'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

type Props = PropsWithClassName<
  PropsWithChildren<{
    title: string | JSX.Element
    name?: string
    titleClassName?: string
  }>
>
export const AdminCollapse = ({
  className,
  title,
  titleClassName,
  children,
  name,
}: Props) => {
  const id = name ?? uniqueId()
  return (
    <div className={clsx('collapse-arrow collapse', className)}>
      <input type="radio" name={id} />
      <div className={clsx(titleClassName, 'collapse-title')}>{title}</div>
      <div className="collapse-content w-full overflow-hidden">{children}</div>
    </div>
  )
}

import { FvDate } from '@fv/client-components'
import { useCarriers } from '@/hooks/useCarriers'

import { AdminButton } from '../../components/shared/AdminButton'
import { AdminLoading } from '../../components/shared/AdminLoading'
import { AdminTable, type ColumnDef } from '../../components/shared/AdminTable'
import { useSystemNotifications } from './queries'
import { type NotificationFormModel } from './types'

type Props = {
  onEdit: (note: NotificationFormModel) => void
  onRemove: (note: NotificationFormModel) => void
}
export const NotificationList = ({ onEdit, onRemove }: Props) => {
  const { data: items, isLoading } = useSystemNotifications()
  const carriers = [{ name: 'All', id: '' }, ...useCarriers()]
  if (isLoading) {
    return <AdminLoading />
  }

  const columns: ColumnDef<NotificationFormModel>[] = [
    { key: 'text', render: r => r.text },
    { key: 'type', render: r => r.type },
    {
      key: 'carrier name',
      render: r =>
        r.carrierId ? carriers.find(c => c.id === r.carrierId)?.name : 'All',
    },
    { key: 'carrier id', render: r => r?.carrierId },
    {
      key: 'expiration',
      render: r => (
        <FvDate format="YYYY-MM-DD hh:mm A" utc val={r.expiration} />
      ),
    },
    {
      key: 'actions',
      render: r => (
        <div className="flex gap-x-2">
          <AdminButton
            className="btn-xs"
            icon="edit"
            onClick={() => onEdit(r)}
          />
          <AdminButton
            className="btn-xs btn-error"
            icon="trash"
            onClick={() => onRemove(r)}
          />
        </div>
      ),
    },
  ]
  return (
    <AdminTable
      data={items}
      rowKey={r => r._id}
      columns={columns}
      emptyContent="No notifications have been created"
    />
  )
}

import { CopyButton } from './CopyButton'

type CodeProps = {
  data: unknown
}

function formatXml(xml: string) {
  let formatted = '',
    indent = ''
  const tab = '  '
  xml.split(/>\s*</).forEach(function (node) {
    if (node.match(/^\/\w/)) indent = indent.substring(tab.length) // decrease indent by one 'tab'
    formatted += indent + '<' + node + '>\r\n'
    if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab // increase indent
  })
  return formatted.substring(1, formatted.length - 3)
}

export const AdminCode = ({ data }: CodeProps) => {
  let value: string

  if (typeof data === 'string') {
    value = data.match(/<\?xml/i) ? formatXml(data) : data
  } else {
    value = JSON.stringify(data, null, 2)
  }

  return (
    <div className="relative">
      <CopyButton
        className="btn-sm text-base-content absolute right-0 top-0 "
        value={value}
      >
        Copy
      </CopyButton>

      <div className="bg-base-300 p-2">
        <code className="text-base-content whitespace-pre-wrap text-xs leading-3">
          {value}
        </code>
      </div>
    </div>
  )
}

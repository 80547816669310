import { useMutation } from '@tanstack/react-query'

import { FvDate } from '@fv/client-components'

import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminSection } from '../../../components/shared/AdminSection'
import { AdminTable } from '../../../components/shared/AdminTable'
import { adminFetch } from '../../../utils'
import { SerializeSlider } from './SerializeSlider'
import { type LoadPanelProps } from './types'

interface FetchOpts {
  loadId: string
}
const useFetchInvoice = () =>
  useMutation(({ loadId }: FetchOpts) =>
    adminFetch<object>(`/loads/${loadId}/fetch-invoice`, {
      method: 'POST',
    }),
  )

export const InvoicePanel = ({ load }: LoadPanelProps) => {
  const fetchInvoice = useFetchInvoice()
  if (load.track?.currentStatus !== 'delivered') return null

  return (
    <AdminSection
      title="Invoice"
      headerActions={
        <>
          <AdminButton
            disabled={!load.hasInvoiceApi}
            icon="dollar-sign"
            className="btn-sm"
            onClick={() => fetchInvoice.mutateAsync({ loadId: load._id })}
          >
            Fetch invoice
          </AdminButton>
          <SerializeSlider loadId={load._id} output="invoice" />
        </>
      }
    >
      {!load.invoice ? (
        <p>No invoice available</p>
      ) : (
        <AdminTable
          disableSort
          columns={[
            {
              key: 'invoiceDate',
              label: 'Invoice date',
              render: d =>
                !d ? 'n/a' : <FvDate val={d.invoiceDate} format="MM/DD/YY" />,
            },
            {
              key: 'invoiceAmount',
              label: 'Amount',
              render: d => d.invoiceAmount,
            },
            {
              key: 'quoteAmount',
              label: 'Quote amount',
              render: d => load.selectedQuote?.amount,
            },
            {
              key: 'invoiceNumber',
              label: 'Invoice number',
              render: d => d.invoiceNumber,
            },
            {
              key: 'source',
              label: 'Source',
              render: d => d.source,
            },
            {
              key: 'payments',
              label: 'Paid',
              render: d => d.payments?.[0]?.amount ?? 'n/a',
            },
            {
              key: 'disputedAmount',
              label: 'Disputed',
              render: d => d.disputedAmount ?? 'n/a',
            },
            {
              key: 'parseStatus',
              label: 'Auto accept result',
              render: d => d.parseStatus ?? 'n/a',
            },
            {
              key: 'createdDate',
              label: 'Created',
              render: d => (
                <FvDate val={d.createdDate} format="MM/DD/YY HH:mm:ss" />
              ),
            },
          ]}
          rowKey={r => r.invoiceNumber}
          data={[load.invoice]}
        />
      )}
    </AdminSection>
  )
}

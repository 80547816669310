import { useState } from 'react'

import { Tooltip } from '@fv/client-components'

import { AdminSliderPanel } from '../../../components/AdminSliderPanel'
import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminLink } from '../../../components/shared/AdminLink'
import { AdminSection } from '../../../components/shared/AdminSection'
import { AdminTable } from '../../../components/shared/AdminTable'
import { friendlyDate } from '../../../utils'
import { AddIrisDispatchForm } from '../../dispatches/AddIrisDispatchForm'
import { ArchiveMissedPickupForm } from '../../dispatches/ArchiveMissedPickupForm'
import { ConfirmPickupForm } from '../../dispatches/ConfirmPickupForm'
import { SerializeSlider } from './SerializeSlider'
import { type LoadPanelProps } from './types'

const PickupForm = ({ load, onChange }: LoadPanelProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <AdminButton
        icon="hashtag"
        onClick={() => setIsOpen(true)}
        className="btn-sm"
      >
        Enter Pickup
      </AdminButton>
      <AdminSliderPanel isOpen={isOpen} closePanel={() => setIsOpen(false)}>
        <ConfirmPickupForm
          load={load}
          onSubmit={() => {
            setIsOpen(false)
            onChange?.()
          }}
        />
      </AdminSliderPanel>
    </>
  )
}

const MissedPickupSlider = ({ load, onChange }: LoadPanelProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <AdminButton
        icon="map-signs"
        onClick={() => setIsOpen(true)}
        className="btn-sm"
      >
        View missed pickup
      </AdminButton>
      <AdminSliderPanel isOpen={isOpen} closePanel={() => setIsOpen(false)}>
        <ArchiveMissedPickupForm load={load} onSuccess={onChange} />
      </AdminSliderPanel>
    </>
  )
}

const ApplyIrisDispatchForm = ({ load, onChange }: LoadPanelProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <AdminButton
        icon="cloud-upload"
        onClick={() => setIsOpen(true)}
        className="btn-sm"
      >
        Add Iris Dispatch
      </AdminButton>
      <AdminSliderPanel isOpen={isOpen} closePanel={() => setIsOpen(false)}>
        <AddIrisDispatchForm
          load={load}
          onSubmit={() => {
            setIsOpen(false)
            onChange?.()
          }}
        />
      </AdminSliderPanel>
    </>
  )
}

export function DispatchesPanel({ load, onChange }: LoadPanelProps) {
  return (
    <AdminSection
      title="Dispatches"
      headerActions={
        <>
          <PickupForm load={load} onChange={onChange} />
          {load.status === 'booked' && load.dispatch?.status !== 'ok' && (
            <ApplyIrisDispatchForm load={load} onChange={onChange} />
          )}

          {!!load.missedPickup?.dateReported && (
            <MissedPickupSlider onChange={onChange} load={load} />
          )}

          {!!load.dispatch && (
            <SerializeSlider loadId={load._id} output="dispatching" />
          )}
        </>
      }
    >
      <AdminTable
        data={load.dispatch?.history}
        rowKey={d => d.eventId}
        columns={[
          {
            key: 'createdDate',
            label: 'Date',
            render: d => friendlyDate(d.createdDate),
          },
          {
            key: 'providerCode',
            label: 'Provider Code',
            render: d => d.providerCode,
          },
          {
            key: 'bol',
            label: 'BOL',
            render: d => {
              const bolUrl = d.files?.find(f => f.type === 'bol')?.url
              return (
                bolUrl && (
                  <AdminLink className="text-xs" href={bolUrl}>
                    View BOL
                  </AdminLink>
                )
              )
            },
          },
          {
            key: 'confirmationNumber',
            label: 'Pickup Number',
            render: d => d.confirmationNumber,
          },
          {
            key: 'masterTrackingNumber',
            label: 'Master Tracking Number',
            render: d => d.masterTrackingNumber,
          },
          {
            key: 'method',
            label: 'Method',
            render: d => d.method,
          },
          {
            key: 'status',
            label: 'Status',
            render: d => d.status,
          },
          {
            key: 'errors',
            label: 'Errors',
            render: d => {
              const errorString =
                d.errors?.map(e => e.rawMessage).join('. ') ?? ''
              return (
                <Tooltip label={errorString}>
                  <span>{errorString.substring(0, 100)}</span>
                </Tooltip>
              )
            },
          },
          {
            key: 'error-type',
            label: 'Error Type',
            render: d => d.errors?.map(e => e.type).join(', '),
          },
        ]}
      />
    </AdminSection>
  )
}

import {
  vMinLength,
  vNumberToString,
  vObject,
  vOutput,
  vPicklist,
  vPipe,
} from '../validation'

export const refNumTypes = [
  'load-num',
  'seal-num',
  'trailer-num',
  'other',
] as const
export type RefNumType = (typeof refNumTypes)[number]
export const loadRefNumSchema = vObject({
  type: vPicklist(refNumTypes),
  value: vPipe(vNumberToString(), vMinLength(1)),
})

export type RefNum = vOutput<typeof loadRefNumSchema>

export class LoadRefNum implements RefNum {
  type: RefNumType
  value: string
}

import { useMutation } from '@tanstack/react-query'

import { showApiError } from '@fv/client-core'
import { type AdminLoad } from '@/types/admin-load'

import { adminFetch } from '../utils'

async function syncTracking(load: AdminLoad) {
  return adminFetch(`/loads/${load._id}/tracking/sync`, {
    method: 'POST',
  })
}

export function useSyncTracking() {
  return useMutation(syncTracking, {
    onError: e => showApiError('Unable to sync tracking', e),
  })
}

import debounce from 'lodash/debounce'
import { useCallback, useRef, useState } from 'react'

import { type CarrierDTO } from '@fv/models'
import { AdminTypeaheadField } from '@/components/AdminTypeaheadField'

import { useSearchCarriers } from '../queries'
import { CarrierStatusBadge } from './CarrierBadges'

type Props = {
  carrier: CarrierDTO
  name: string
  onCarrierChange?: (value: CarrierDTO) => void
}

export const CarrierTypeAhead = ({ carrier, name, onCarrierChange }: Props) => {
  const [searchText, setSearch] = useState(carrier?.name || '')
  const setSearchDebounced = useRef(debounce(v => setSearch(v), 500)).current
  const carriersQuery = useSearchCarriers(searchText)
  const carriers = carriersQuery.data?.pages?.at(0)?.data || []

  const localOnInputChange = useCallback(
    (value: string) => {
      setSearchDebounced(value)
    },
    [setSearchDebounced],
  )

  function selectCarrier(newCarrier: CarrierDTO | null) {
    onCarrierChange(newCarrier)
  }

  return (
    <AdminTypeaheadField
      formatOptionLabel={v => (
        <div className="flex flex-col leading-tight">
          <div>
            {v.name}{' '}
            <CarrierStatusBadge status={v.status} className="badge-xs" />
          </div>

          <small>{v._id}</small>
        </div>
      )}
      getOptionValue={v => v._id}
      isClearable
      isLoading={carriersQuery.isFetching}
      name={name}
      onChange={selectCarrier}
      onInputChange={localOnInputChange}
      options={carriers}
      filterOption={null}
      value={carrier}
      placeholder="Carrier"
    />
  )
}

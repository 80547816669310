import without from 'lodash/without'

import {
  vMilitaryTime,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import { Workflow } from './common'
import { LocationType } from './load-location'

export const legacyShipTypes = [
  'airport',
  'business dock',
  'business no dock',
  'construction',
  'farm',
  'limited access',
  'military',
  'mine',
  'prison',
  'pier',
  'place of worship',
  'residential',
  'school',
  'trade show',
] as const

export const shipTypes = [
  'airport',
  'business-dock',
  'business-no-dock',
  'construction-site',
  'farm',
  'limited-access',
  'military',
  'mine',
  'pier',
  'place-of-worship',
  'prison',
  'residential',
  'school',
  'tradeshow',
] as const

export type LegacyShipType = (typeof legacyShipTypes)[number]
export type ShipType = (typeof shipTypes)[number]

export const locationAccessorials = [
  {
    key: 'schedule',
    name: 'Appointment required',
    mode: 'truckload',
    shipTypes: legacyShipTypes,
    locTypes: ['origin', 'stop', 'destination'],
  },
  {
    key: 'driver-assist',
    name: 'Driver assist',
    mode: 'truckload',
    shipTypes: legacyShipTypes,
    locTypes: ['origin', 'stop', 'destination'],
  },
  {
    key: 'notify',
    name: 'Arrival notification',
    mode: 'truckload',
    shipTypes: legacyShipTypes,
    locTypes: ['origin', 'stop', 'destination'],
  },
  {
    key: 'limited-access',
    name: 'Limited access',
    mode: 'ltl',
    shipTypes: ['limited access'],
    locTypes: ['origin', 'destination'],
  },
  {
    key: 'inside',
    name: 'Inside',
    mode: 'ltl',
    shipTypes: without(legacyShipTypes, 'construction', 'business dock'),
    locTypes: ['origin', 'destination'],
  },
  {
    key: 'tradeshow',
    name: 'Tradeshow',
    mode: 'ltl',
    shipTypes: ['trade show'],
    locTypes: ['origin', 'destination'],
  },
  {
    key: 'residential',
    name: 'Residential',
    mode: 'ltl',
    shipTypes: ['residential'],
    locTypes: ['origin', 'destination'],
  },
  {
    key: 'sort-and-segregate',
    name: 'Sort & segregate',
    mode: 'ltl',
    shipTypes: legacyShipTypes,
    locTypes: ['destination'],
  },
  {
    key: 'construction-site',
    name: 'Construction site',
    locTypes: ['origin', 'destination'],
    shipTypes: ['construction'],
    mode: 'ltl',
  },
  {
    key: 'farm',
    name: 'Farm',
    locTypes: ['origin', 'destination'],
    shipTypes: ['farm'],
    mode: 'ltl',
  },
  {
    key: 'military',
    name: 'Government/Military',
    locTypes: ['origin', 'destination'],
    shipTypes: ['military'],
    mode: 'ltl',
  },
  {
    key: 'airport',
    name: 'Airport',
    locTypes: ['origin', 'destination'],
    shipTypes: ['airport'],
    mode: 'ltl',
  },
  {
    key: 'place-of-worship',
    name: 'Place of worship',
    locTypes: ['origin', 'destination'],
    shipTypes: ['place of worship'],
    mode: 'ltl',
  },
  {
    key: 'school',
    name: 'School',
    locTypes: ['origin', 'destination'],
    shipTypes: ['school'],
    mode: 'ltl',
  },
  {
    key: 'mine',
    name: 'Mine',
    locTypes: ['origin', 'destination'],
    shipTypes: ['mine'],
    mode: 'ltl',
  },
  {
    key: 'pier',
    name: 'Pier',
    locTypes: ['origin', 'destination'],
    shipTypes: ['pier'],
    mode: 'ltl',
  },
  {
    key: 'prison',
    name: 'Prison',
    locTypes: ['origin', 'destination'],
    shipTypes: ['prison'],
    mode: 'ltl',
  },
  {
    key: 'liftgate',
    name: 'Liftgate',
    locTypes: ['origin', 'destination'],
    shipTypes: without(legacyShipTypes, 'business dock'),
    mode: 'ltl',
  },
  {
    key: 'signature-required',
    name: 'Signature',
    locTypes: ['destination'],
    shipTypes: legacyShipTypes,
    mode: 'parcel',
  },
  {
    key: 'adult-signature-required',
    name: 'Adult signature',
    locTypes: ['destination'],
    shipTypes: legacyShipTypes,
    mode: 'parcel',
  },
  {
    key: 'indirect-signature-required',
    name: 'Indirect signature',
    locTypes: ['destination'],
    shipTypes: ['residential'],
    mode: 'parcel',
  },
  {
    key: 'home-delivery-premium',
    name: 'Home Delivery Premium',
    locTypes: ['destination'],
    shipTypes: legacyShipTypes,
    mode: 'parcel',
  },
  {
    key: 'twic',
    name: 'TWIC required',
    mode: 'truckload',
    shipTypes: legacyShipTypes,
    locTypes: ['origin', 'stop', 'destination'],
  },
] as const satisfies readonly {
  key: string
  name: string
  locTypes: readonly LocationType[]
  shipTypes: readonly LegacyShipType[]
  mode: Workflow
}[]

export type LocationAccessorialType =
  (typeof locationAccessorials)[number]['key']

export const locationAccessorialSchema = vObject({
  key: vPicklist(locationAccessorials.map(a => a.key)),
  opensAt: vOptional(vMilitaryTime()),
  closesAt: vOptional(vMilitaryTime()),
  tradeshowBooth: vOptional(vString()),
})
export type LocationAccessorialDTO = vOutput<typeof locationAccessorialSchema>

export const ltlLocationAccessorial = vObject({
  key: vPicklist(
    locationAccessorials
      .filter(a => a.mode === 'ltl')
      .map<LocationAccessorialType>(a => a.key),
  ),
  tradeshowBooth: vOptional(vString()),
})

import { vObject, vOutput, vString } from '../validation'

export const signInSchema = vObject({
  username: vString(),
  password: vString(),
})

export type SignInDTO = vOutput<typeof signInSchema>

export const vendorSigninSchema = vObject({
  email: vString(),
})

export type VendorSigninDTO = vOutput<typeof vendorSigninSchema>

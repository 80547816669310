import dayjs from 'dayjs'

import { ValidatedForm } from '@fv/client-components'
import { useFormModelState } from '@fv/client-core'
import {
  systemNotificationTypes,
  type UpsertSystemNotificationDTO,
} from '@fv/models'
import { useCarriers } from '@/hooks/useCarriers'

import { AdminButton } from '../../components/shared/AdminButton'
import { AdminFormGroup } from '../../components/shared/AdminFormGroup'
import { AdminSelect } from '../../components/shared/AdminSelect'
import { useCreateNotification, useUpdateNotification } from './mutations'
import { type NotificationFormModel } from './types'

type Props = {
  onClose?: () => void
  initialValue?: NotificationFormModel
}
const dateInputFormat = 'YYYY-MM-DDTHH:mm'
const options = [...systemNotificationTypes]

export const NotificationForm = (props: Props) => {
  const { onClose, initialValue } = props
  const carriersOptions = [{ name: 'All', id: '' }, ...useCarriers()]
  const { register, value, setValue } =
    useFormModelState<NotificationFormModel>({
      initialValue: initialValue
        ? {
            ...initialValue,
            expiration: dayjs
              .utc(initialValue.expiration)
              .local()
              .format(dateInputFormat),
          }
        : {
            type: 'warning',
            expiration: dayjs().format(dateInputFormat),
            text: '',
            _id: '',
          },
    })
  const createSystemNotification = useCreateNotification()
  const updateNotification = useUpdateNotification()

  const isSaving = createSystemNotification.isLoading

  const handleSubmit = async () => {
    if (!value.expiration) return
    const body: UpsertSystemNotificationDTO = {
      ...value,
      carrierId: value.carrierId || undefined,
      expiration: dayjs(value.expiration).utc().toDate().toISOString(),
    }
    if (initialValue?._id) {
      await updateNotification.mutateAsync({ id: initialValue._id, body })
    } else {
      await createSystemNotification.mutateAsync(body)
    }
    onClose?.()
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
        <AdminFormGroup label="Type">
          <AdminSelect
            options={options}
            className="w-full"
            name="type"
            required
            {...register('type')}
          />
        </AdminFormGroup>
        <AdminFormGroup label="Expiration">
          <input
            type="datetime-local"
            name="expiration"
            className="input input-bordered"
            required
            {...register('expiration')}
          />
        </AdminFormGroup>
      </div>
      <div>
        <AdminFormGroup label="carrier">
          <AdminSelect
            options={
              carriersOptions?.map(c => ({
                text: c?.name || '',
                value: c?.id || '',
              })) || []
            }
            className="w-full"
            name="carrier"
            required
            {...register('carrierId')}
          />
        </AdminFormGroup>
      </div>
      <div>
        <AdminFormGroup label="Text">
          <textarea
            className="input input-bordered w-full p-2 h-32"
            rows={5}
            required
            value={value.text}
            onChange={e => setValue(p => ({ ...p, text: e.target.value }))}
          />
        </AdminFormGroup>
      </div>
      <div className="flex justify-end gap-2 pt-4">
        <AdminButton className="btn-error" onClick={onClose} loading={isSaving}>
          Cancel
        </AdminButton>
        <AdminButton className="btn-primary" type="submit" loading={isSaving}>
          Save
        </AdminButton>
      </div>
    </ValidatedForm>
  )
}
